$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-shipping-details {
		&__list {
			padding: 0;
			list-style: none;
			li {
				color: $accent;
				font-size: $font-size-root;
				line-height: 36px;
				&:first-of-type {
					display: flex;
					align-items: center;
					justify-content: space-between;
					> span {
						padding-right: gutter-size(3);
					}
				}
			}
		}
		&__edit {
			font-weight: $font-weight-regular !important;
			font-size: $font-size-root;
			text-transform: unset;
			text-decoration: underline;
		}
	}
}
