$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-alert {
		&__alert {
			background-color: $gray-100;
			border-radius: $border-radius-root;
			.v-alert__wrapper {
				padding-bottom: gutter-size(2);
			}
			.v-btn__content {
				.v-icon {
					width: gutter-size(6);
					height: gutter-size(6);
					font-size: 24px;
				}
			}
			.v-alert__icon {
				height: gutter-size(8);
				font-size: 32px;
			}

			&.scl-alert--success .scl-alert__inverted-icon-wrapper .scl-alert__inverted-icon {
				color: white !important;
				&.fa-shopping-cart {
					margin-left: -3px; //offset required to appear centered
				}
			}
			.scl-alert__inverted-icon-wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				min-width: 32px;
				height: 32px;
				margin-right: 16px;
				background-color: $primary-dark;
				border-radius: 16px;
			}
		}
		&__title {
			margin-bottom: gutter-size(2);
			font-weight: $font-weight-bold;
			font-size: $font-size-root;
			line-height: $line-height-sm;
		}
		&__copy {
			margin-bottom: 0;
			color: $body-color;
			a {
				text-decoration: underline;
				#{$interact} {
					text-decoration: none;
				}
			}
		}
		.v-alert__dismissible {
			align-self: flex-start;
			margin: gutter-size(1) 0 0;
			background-color: $info;
			.v-icon {
				width: 30px;
				height: 30px;
				color: $gray-300 !important;
			}
		}
		&--contextual {
			border: 1px solid $border-color;
			border-bottom: 0;
			.scl-alert__title {
				color: $primary !important;
			}
			a,
			.warning--text {
				color: $primary-dark !important;
				#{$interact} {
					color: $primary-dark;
				}
			}
			.v-alert__border {
				border-width: 2px;
			}
			.v-alert__icon {
				align-self: center;
				height: 40px;
				margin-right: gutter-size(5);
				font-size: 40px;
			}
		}
		&--error {
			.scl-alert__title,
			a {
				color: $error;
				#{$interact} {
					color: $error;
				}
			}
			.v-alert__dismissible {
				background-color: $error;
			}
		}
		&--flat {
			background: none;
			a {
				color: $primary-dark !important;
				#{$interact} {
					color: $primary-dark;
				}
			}
			.v-alert__wrapper {
				align-items: center;
				padding: 0;
			}
			.v-alert__icon {
				height: 32px;
				color: $primary-dark;
				font-size: 32px;
			}
		}
		&--info {
			.scl-alert__title,
			a {
				color: $info;
				#{$interact} {
					color: $info;
				}
			}
			.v-alert__dismissible {
				background-color: $info;
			}
		}
		&--success {
			.v-alert__wrapper {
				align-items: flex-start;
			}
			.scl-alert__title,
			a {
				color: $success;
				#{$interact} {
					color: $success;
				}
			}
			.v-alert__dismissible {
				background-color: $success;
			}
		}
		&--warning {
			background-color: $accent !important;
			.v-btn__content {
				.v-icon {
					color: #13181b !important;
				}
			}
			.scl-alert__copy {
				color: $white;
			}
			.scl-alert__title,
			a {
				color: $warning;
				#{$interact} {
					color: $warning;
				}
			}
			.v-alert__dismissible {
				background-color: $warning;
			}
		}
		&--cart-order-summary {
			border: 1px solid $border-color;
			border-bottom: 0;
			.scl-alert__title,
			a,
			.warning--text {
				color: $primary-dark !important;
				#{$interact} {
					color: $primary;
				}
			}
			.v-alert__border {
				border-width: 2px;
			}
			.v-alert__icon {
				align-self: center;
				height: 40px;
				margin-right: gutter-size(5);
				font-size: 40px;
			}
		}
	}
}
