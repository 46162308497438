$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-search-listing-sort {
		&__container {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
		}
		&__title {
			width: 100%;
			margin-right: gutter-size(2);
			font-weight: $font-weight-bold;
		}
		&__select {
			flex-grow: 0;
			width: 100%;
			.v-icon {
				margin-top: gutter-size(2);
				color: $primary-dark;
			}
			.v-list-item__title {
				font-size: $font-size-root;
			}
		}
		@include breakpoint($sm) {
			&__container {
				justify-content: flex-end;
			}
			&__title {
				width: auto;
			}
			&__select {
				width: 250px;
			}
		}
		@include breakpoint($md) {
			width: 322px;
		}
		@include breakpoint($lg) {
			width: auto;
		}
	}
}
