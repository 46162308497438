/*! variables */

// ---------------------------------------------------------------------
// layout
// ---------------------------------------------------------------------

$xs: 0;
$sm: 768px;
$md: 1024px;
$lg: 1280px;
$xl: 1608px;
$xsOnly: $xs ($sm - 1);
$smOnly: $sm ($md - 1);
$mdOnly: $md ($lg - 1);
$lgOnly: $lg ($xl - 1);

$spacer: 4px;
@function gutter-size($gutter-multiplier) {
	@return $spacer * $gutter-multiplier;
}

// ---------------------------------------------------------------------
// colours
// ---------------------------------------------------------------------

// base
// https://www.color-hex.com/color/808080
$white: #fff;
$gray-100: #f4f4f4;
$gray-200: #e5e5e5;
$gray-300: #dddfe0;
$gray-400: #C5C5C6;
$gray-500: #abafb2;
$gray-600: #6D6E71;
$gray-700: #404040;
$gray-800: #333;
$gray-900: #262626;
$black: #0d1012;

// brand
$primary: #1D4789;
$primary-dark: #012169;
$primary-light: #D6DBE7;

$secondary: #F3C828;
$secondary-dark: #F3C828;

$accent: #2C2A29;
$accent-light: #6D6E71;
$accent-lighter: #abafb2;
$accent-lightest: #dddfe0;

// contextual
$info: $accent;
$success: $primary;
$warning: $secondary-dark;
$error: #c92d0e;

// typography
$body-color: $accent;
$headings-color: $accent;

// links
$link-color: $primary;
$link-hover-color: $link-color;

// elements
$border-color: $gray-400;
$border-hover-color: $gray-500;
$input-color: $black;

// 3rd party brands
$colour-Facebook: #3b5998;
$colour-Google: #cf4332;
$colour-Instagram: #3f729b;
$colour-Linkedin: #0077b5;
$colour-Twitter: #00aced;
$colour-Youtube: #ae2b25;

// ---------------------------------------------------------------------
// typography
// ---------------------------------------------------------------------

// scaleable type min / max scale points
$min-type-width: $sm;
$max-type-width: $xl;

// font families
$body-font-family: "MuseoSans";
$heading-font-family: $body-font-family;

// font sizes
$font-size-root: 18px;
$font-size-xsmall: $font-size-root * 0.77; // 14
$font-size-small: $font-size-root * 0.88; // 16
$font-size-large: $font-size-root * 1.11; // 20
$font-size-xlarge: $font-size-root * 1.22; // 22

// font sizes: headings
// h6
$font-size-H6-max: $font-size-root;
$font-size-H6-min: $font-size-root;
// h5
$font-size-H5-max: $font-size-xlarge;
$font-size-H5-min: $font-size-H6-max;
// h4
$font-size-H4-max: 24px;
$font-size-H4-min: $font-size-H5-max;
// h3
$font-size-H3-max: 32px;
$font-size-H3-min: $font-size-H4-max;
// h2
$font-size-H2-max: 48px;
$font-size-H2-min: $font-size-H3-max;
// h1
$font-size-H1-max: 64px;
$font-size-H1-min: $font-size-H2-max;

// font weights
$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

// line height
$line-height-root: 1.5;
$line-height-xs: 1;
$line-height-sm: 1.2;
$line-height-lg: 1.8;
$line-height-xl: 2;

// links
$link-decoration: underline;
$link-hover-decoration: none;

// link states
$interact: "&:focus, &:hover, &:active";

// ---------------------------------------------------------------------
// elements
// ---------------------------------------------------------------------

$border-radius-root: 3px;
$opacity-base: 1;
$opacity-lots: $opacity-base * 0.75;
$opacity-half: $opacity-base * 0.5;
$opacity-little: $opacity-base * 0.333;
$opacity-off: 0;
// shadows
$shadow-box: 0 gutter-size(1) gutter-size(5) rgba(45, 55, 62, 0.08);
$shadow-text: 0 gutter-size(0.5) gutter-size(0.5) rgba(45, 55, 62, 0.4);
$shadow-btn: 0 gutter-size(0.5) gutter-size(0.5) rgba(45, 55, 62, 0.08);
$shadow-inset: inset 0 gutter-size(1) gutter-size(3) rgba(45, 55, 62, 0.08);

// ---------------------------------------------------------------------
// animation
// ---------------------------------------------------------------------

// timing
$timing-base: 0.1s;
$timing-fastest: $timing-base;
$timing-faster: $timing-base * 2;
$timing-fast: $timing-base * 3;
$timing-medium: $timing-base * 5;
$timing-slow: $timing-base * 7;
$timing-slower: $timing-base * 8;
$timing-slowest: $timing-base * 9;

// easing
$easing: ease-in-out;

// ---------------------------------------------------------------------
// misc
// ---------------------------------------------------------------------

// cache bust
$cache-bust: "?v=#{random(9000) + 999}";

// img path is in vue.config now as it needs to change for dev and prod
// $image-path: "/assets/img";

// z-index
$z-index: (
	penthouse: 111111,
	level5: 11111,
	level4: 1111,
	level3: 111,
	level2: 11,
	level1: 1,
	ground: 0,
	basement: -1,
	hell: -111111
);
@function zindex($key) {
	@return map-get($z-index, $key);
	@if not map-has-key($z-index, $key) {
		@warn "zindex '#{$key}' not found.";
	}
}

  @font-face {
	font-family: 'MuseoSans';
	font-weight: 500;
	src: url("#{$font-path}/MuseoSans-500.woff2");

 }
 @font-face {
	font-family: 'MuseoSans';
	font-weight: 700;
	src: url("#{$font-path}/MuseoSans-700.woff2");

 }
 @font-face {
	font-family: 'MuseoSans';
	font-weight: 900;
	src: url("#{$font-path}/MuseoSans-900.woff2");

 }