$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-summary {
		color: $gray-600;
		&__image {
			background: $white;
			border: 1px solid $border-color;
		}
		&__readmore {
			font-weight: $font-weight-bold;
			.v-icon {
				color: $primary-dark;
			}
			#{$interact} {
				a,
				.v-icon {
					transition: all $easing $timing-faster;
					color: $secondary-dark;
				}
			}
		}
		&__actions {
			display: flex;
			flex-wrap: wrap;
			.btn {
				flex: 1 0 100%;
				border-color: $primary-dark;
				color: $primary-dark;
				#{$interact} {
					border-color: $secondary-dark;
					color: $secondary-dark;
				}
				&:first-child {
					margin-bottom: gutter-size(2);
				}
			}
			@include breakpoint($sm) {
				flex-wrap: nowrap;
				.btn {
					flex: 0 1 50%;
					&:first-child {
						margin-bottom: 0;
					}
				}
			}
		}
			// print css
	@media print {		
		&__image {
			.v-image {
				max-height: 250px;
			}
			.v-image__image {
				background-size:contain !important;
			}
		}
		.scl-product-summary__description-full {
			display: block !important;
			margin-bottom: 16px;
		}
		&__readmore {
			display: none !important;
		}
		&__actions {
			display: none;
		}
		 h1{
			 font-size: 30px;
		 }
	}
	}
}
