$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-latest-articles {
		position: relative;
		height: 100%;
		padding: gutter-size(6) gutter-size(5) gutter-size(3);
		background: $gray-100;
		@include breakpoint($sm) {
			padding: gutter-size(12) gutter-size(10) gutter-size(6);
		}
		&__link {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: gutter-size(3) 0;
			color: $primary-dark;
			font-weight: $font-weight-regular;
			border-top: 1px solid $border-color;
			span {
				position: relative;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			#{$interact} {
				color: $primary;
				.scl-latest-articles__icon {
					color: $primary;
				}
			}
		}
		&__image {
			min-width: gutter-size(7.5);
			max-width: gutter-size(7.5);
		}
		&__icon {
			margin-left: gutter-size(2);
			color: $primary-dark;
			font-size: $font-size-root;
		}
	}
}
