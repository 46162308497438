$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-events-filtering {
		&__filter-button {
			width: 100%;
			margin-bottom: gutter-size(4);
		}
		&__container {
			position: relative;
			height: 0;
			opacity: 0;
			transition: opacity $easing $timing-fast;
			&--active {
				height: auto;
				padding: gutter-size(4) 0;
				opacity: 1;
			}
		}
		&__select {
			margin-bottom: gutter-size(6);
			fieldset {
				top: -10px;
				border: 1px solid $gray-300;
			}
			.v-input__prepend-inner {
				flex-grow: 1;
				flex-shrink: 0;
				align-self: center;
				margin: 0;
				color: $accent;
				font-weight: $font-weight-semibold;
				font-size: $font-size-root;
				line-height: $line-height-sm;
			}
			&.v-select--is-menu-active {
				box-shadow: $shadow-box;
				fieldset {
					border-bottom: 0;
					border-radius: $border-radius-root $border-radius-root 0 0;
				}
				.v-input__prepend-inner {
					color: $primary-dark;
				}
			}
			.v-select__selections {
				opacity: 0;
			}
			.v-icon {
				color: $primary-dark !important;
			}
			.v-menu__content {
				border: 1px solid $gray-300;
				border-top: 0;
				border-radius: 0 0 $border-radius-root $border-radius-root;
				box-shadow: 0 gutter-size(3) gutter-size(5) rgba(45, 55, 62, 0.08);
			}
			.v-select-list {
				position: relative;
				&:before {
					position: absolute;
					top: 0;
					left: gutter-size(3);
					width: calc(100% - #{gutter-size(6)});
					border-top: 1px solid $gray-300;
					content: "";
				}
			}
			.v-list-item__action {
				margin-right: gutter-size(4);
			}
			.fa-square {
				color: $gray-500 !important;
			}
			.fa-check-square {
				color: $primary !important;
			}
			.v-list-item--active {
				&:before {
					background-color: $white;
				}
				.v-list-item__title {
					color: $accent;
				}
			}
		}
		&__clear {
			float: right;
			text-transform: unset;
		}
	}
}
