$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-information-list {
		position: relative;
		&__container {
			display: flex;
			flex-wrap: wrap;
			background-color: transparent;
		}
		&__item {
			display: flex;
			flex: 1 1 100%;
			flex-wrap: wrap;
			align-items: flex-start;
			padding: gutter-size(6) 0;
			border-bottom: 1px solid $border-color;

			@include breakpoint($sm) {
				flex: 0 0 50%;
				padding: gutter-size(10) 0;
			}
		}
		&__row {
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
		}
		&__col {
			width: 100%;
		}
		&__title {
			margin: 0;
			color: $accent;
		}
		&__subtitle {
			margin-bottom: gutter-size(2);
			color: $accent-light;
		}
	}
}
