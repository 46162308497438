$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-collection-summary {
        color: $gray-600;
        &__image {
            margin: gutter-size(7.5) 0;
            background: $white;
            border: 1px solid $border-color;
        }
        &__readmore {            
            font-weight: $font-weight-bold;
            .v-icon {
                color: $primary-dark;
            }
            #{$interact} {
				a,
				.v-icon {
					transition: all $easing $timing-faster;
					color: $secondary-dark;
				}
			}
        }
        .btn {
            border-color: $primary-dark;
            color: $primary-dark;
            #{$interact} {
                border-color: $secondary-dark;
                color: $secondary-dark;
            }
        }
	}
}
