$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-cta {
		&__button {
			justify-content: space-between !important;
			height: auto;
			color: $primary-dark;
			letter-spacing: 0;
			text-transform: none;
			flex-direction: column;
		}
		&__icon {
			font-size: $font-size-root;
		}
		.v-btn__content {
			width: 100%;
		}
	}
}
