$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-cart-order-summary {
		&__wrapper {
			position: relative;
			margin-bottom: gutter-size(4);
			margin-top: gutter-size(22);
			padding: gutter-size(4);
			border: 1px solid $border-color;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
		}
		&__overlay {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			background: rgba($white, $opacity-lots);
		}
		&__title {
			margin-bottom: gutter-size(4);
			padding: gutter-size(4);
			border-bottom: 1px solid $border-color;
		}
		&__items {
			padding: gutter-size(4);
			color: $accent;
			list-style: none;
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: gutter-size(4);
				&.is-promo {
					color: $primary;
				}
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		&__total {
			display: flex;
			justify-content: space-between;
			margin-top: gutter-size(4);
			padding: gutter-size(4);
			font-size: $font-size-xlarge;
			border-top: 1px solid $border-color;
			span {
				color: $primary;
				font-weight: bold;
			}
		}
	}
}
