$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-image-gallery {
		&__img {
			cursor: pointer;
		}
		&__modal {
			height: 100%;
			box-shadow: none;
			&-img {
				max-width: 100%;
				max-height: 100%;
				margin: 0 auto;
			}
		}
		&__carousel {
			.v-icon {
				height: gutter-size(4) !important;
			}
			&-item {
				position: relative;
				.v-responsive__content {
					display: flex;
					align-items: center;
				}
			}
		}
		&__close-icon {
			position: absolute;
			top: gutter-size(3);
			right: gutter-size(3);
			color: $white;
			// Fix icon not appearing on safari
			-webkit-appearance: none;
		}
	}
}
