$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-content-gate {
		position: relative;
		margin-bottom: gutter-size(10);
		&__content {
			position: relative;
			&:after {
				position: absolute;
				top: 20px;
				right: 0;
				bottom: 0;
				left: 0;
				background: linear-gradient(0deg, rgba($white, 1) 15%, rgba($white, 0) 100%);
				content: "";
				@include breakpoint($sm) {
					background: linear-gradient(0deg, rgba($white, 1) 50%, rgba($white, 0) 100%);
				}
			}
		}
		&__container {
			padding: gutter-size(6);
			background-color: $gray-100;
			border: 1px solid $border-color;
			border-bottom: gutter-size(2) solid $secondary-dark;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
		}
		&__title {
			margin-bottom: gutter-size(6);
		}
		&__btn {
			width: 100%;
			margin: gutter-size(4) 0 0;
		}
		&__btn--login {
			border-width: 2px;
		}
		&__btn--join {
			color: $white;
		}
		@include breakpoint($sm) {
			&__container {
				padding: gutter-size(10);
			}
			&__btn {
				width: auto;
				+ .scl-content-gate__btn {
					margin-left: gutter-size(6);
				}
			}
		}
	}
}
