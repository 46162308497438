$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-image-list {
		position: relative;

		&__item {
			padding: gutter-size(6) 0;
			p {
				margin-bottom: 0;
				color: $accent;
				span {
					margin-right: gutter-size(1);
				}
				a {
					text-decoration: underline;
				}
			}
			a {
				color: $primary-dark;
				transition: all $easing $timing-faster;
				#{$interact} {
					color: $primary;
				}
			}
			&--divide {
				border-top: 1px solid $border-color;
			}
		}
		&__row {
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
		}
		&__col {
			width: 100%;
		}
		&__rounded {
			border-radius: 50%;
		}
		&__title {
			margin-top: gutter-size(2);
			color: $accent;
			#{$interact} {
				color: $primary;
			}
		}
		&__subtitle {
			margin-bottom: gutter-size(2);
			color: $accent-light;
			font-weight: $font-weight-bold;
		}
		&__link {
			display: inline-block;
			padding: 0;
			color: $primary-dark;
			font-weight: $font-weight-bold;
			text-decoration: none !important;
		}
		&__icon {
			height: gutter-size(4);
			margin-left: gutter-size(2);
		}
		&--portal {
			.scl-image-list__list {
				background: transparent;
			}
		}
	}
}
