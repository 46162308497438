$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-awards-calendar {
        // Fill in with styling if required
        &__link {
			font-weight: $font-weight-regular !important;
			text-transform: unset;
			text-decoration: underline;
		}
	}
}
