$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-payment-details {
		margin-bottom: gutter-size(10);
		&__btn {
			margin-right: gutter-size(4);
			padding: 0;
			&.is-active {
				border: 1px solid $primary;
			}
		}
		hr {
			display: block;
			width: 100%;
			height: 1px;
			margin: gutter-size(6) 0;
			color: transparent;
			background-color: $gray-300;
			border: 0;
		}
	}
}
