$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-related-articles-list {
		position: relative;
		&__title {
			@include h4;
			margin-bottom: gutter-size(5);
			padding-bottom: gutter-size(2);
			font-weight: $font-weight-bold;
			border-bottom: 1px solid $border-color;
		}
		&__item {
			margin: gutter-size(2) 0;
			padding: 0;
		}
		&__item-link {
			display: flex;
			align-items: center;
			width: 100%;
			padding: gutter-size(2) 0;
			border: 1px solid $border-color;
			border-radius: $border-radius-root;
			box-shadow: none;
		}
		&__item-image {
			min-width: gutter-size(7.5);
			max-width: gutter-size(7.5);
			margin: 0 gutter-size(4);
		}
		&__item-content {
			position: relative;
			padding-left: gutter-size(4);
			&:before {
				position: absolute;
				top: 12.5%;
				left: 0;
				width: 1px;
				height: 75%;
				background: $gray-200;
				content: "";
			}
			span {
				display: block;
				font-size: $font-size-xsmall;
			}
			p {
				margin-bottom: 0;
				font-weight: $font-weight-bold;
			}
		}
	}
}
