$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-cart-add {
		margin-top: gutter-size(10);
		.scl-cart-line-items__quantity {
			margin-right: gutter-size(6);
			.v-icon {
				color: $primary-dark;
			}
		}
		.scl-cart-line-items__input {
			margin-top: 0;
		}
		&__btn {
			width: 100%;
			text-transform: unset;
			.v-icon--left {
				margin-right: gutter-size(4);
			}
		}
		@include breakpoint($sm) {
			&__container {
				display: flex;
				align-items: center;
			}
			&__btn {
				width: 384px;
			}
		}
	}
}
