$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-card--image {
		flex: 1 1 0;
		height: 100%;
		margin: 0;
		.v-card {
			height: 100%;
		}
		.v-image {
			height: 100%;
		}
	}
}
