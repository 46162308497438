$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-article-card-carousel {
		&__header {
			display: block;
			@include breakpoint($sm) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: gutter-size(6);
			}
		}
		&__header-title {
			margin-bottom: 0;
			color: $white;
		}
		&__header-link {
			color: $white;
		}
		&__carousel {
			height: 100% !important;
			overflow: visible;
			.v-carousel__item {
				height: 100% !important;
				overflow: visible;
			}
			.v-window__container {
				height: 100% !important;
				padding-bottom: gutter-size(14);
			}
			.v-window__prev,
			.v-window__next {
				top: calc(100% - #{gutter-size(11)});
				z-index: zindex(level2);
				margin: 0;
				background: none;
				.v-btn {
					color: $white;
					svg {
						width: 32px !important;
						height: 32px !important;
						font-size: 32px !important;
					}
				}
			}
		}
		.v-carousel__controls {
			justify-content: center;
			&__item {
				margin: 0;
				&:before {
					display: none;
				}
				svg {
					width: gutter-size(4) !important;
					height: gutter-size(4) !important;
					color: $gray-100;
					font-size: $font-size-root !important;
					border: 2px solid $white;
					border-radius: 50%;
					opacity: 1;
				}
			}
			.v-item--active {
				svg {
					color:$white;
					background-color: $white;
				}
			}
		}
		&__card {
			display: flex;
			flex-direction: column;
			height: 100%;
			&:before {
				content: none;
			}
		}
		&__content {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: gutter-size(2) gutter-size(6);
		}
		&__chip-group {
			margin-bottom: gutter-size(4);
			.v-slide-group__next,
			.v-slide-group__prev {
				position: absolute;
				top: 0;
				right: 0;
				width: gutter-size(8);
				height: 100%;
				background: linear-gradient(-90deg, $white 40%, transparent);
				svg {
					display: none;
				}
			}
		}
		&__chip {
			padding: gutter-size(1) gutter-size(3);
			color: $accent;
			font-size: $font-size-root;
			background: $secondary;
			border-radius: $border-radius-root;
			opacity: $opacity-base;
		}
		&__title {
			font-weight: $font-weight-bold;
			word-break: break-word;
		}
		&__text {
			margin-bottom: auto;
			font-weight: $font-weight-regular;
			font-size: $font-size-root;
			p {
				margin-bottom: 0;
				word-break: break-word;
			}
		}
		&__info {
			display: flex;
			margin-top: auto;
			padding-top: gutter-size(4);
		}
		&__info-date {
			margin-right: gutter-size(3);
			color: $accent-light;
			font-size: $font-size-small;
		}
		&__info-time {
			padding-left: gutter-size(3);
			color: $accent-light;
			font-size: $font-size-small;
			border-left: 1px solid $gray-100;
		}
		&--dark {
			.scl-article-card-carousel__header-title, .scl-article-card-carousel__header-link {
				color: $primary-dark;
			}
			.v-window__prev,
			.v-window__next {
				top: calc(100% - #{gutter-size(11)});
				z-index: zindex(level2);
				margin: 0;
				background: none;
				.v-btn {
					color: $primary-dark;
					svg {
						width: 32px !important;
						height: 32px !important;
						font-size: 32px !important;
					}
				}
			}
		
		.v-carousel__controls {
			justify-content: center;
			&__item {
				margin: 0;
				&:before {
					display: none;
				}
				svg {
					width: gutter-size(4) !important;
					height: gutter-size(4) !important;
					color: $gray-100;
					font-size: $font-size-root !important;
					border: 2px solid $primary-dark;
					border-radius: 50%;
					opacity: 1;
				}
			}
			.v-item--active {
				svg {
					color:$primary-dark;
					background-color: $primary-dark;
				}
			}
		}
		}
	}
}
