$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-store-locator {
		height: 100%;
		color: $white;
		&__vertical {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: gutter-size(6);
			&-heading {
				height: 100%;
			}
			background: $primary;
			&--auto {
				height: auto;
			}
		}

		&__button {
			min-width: 100%;
			@include breakpoint($sm) {
				min-width: 200px;
			}
		}
		&__link {
			display: block;
			margin-top: gutter-size(4);
			color: $white;
			text-align: center;
		}
		h4 {
			color: $white;
		}
		.v-input__control {
			min-height: 64px;
		}
	}
}
