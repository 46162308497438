$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-carousel-category {
		height: 100%;
		&__slide {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: calc(100% + #{gutter-size(14)});
		}
		&__row {
			height: 100%;
		}
		&__carousel {
			height: 100% !important;
			overflow: visible;
			.v-carousel__item {
				height: 100% !important;
			}
			.v-window__container {
				height: 100% !important;
				padding-bottom: gutter-size(14);
			}
			.v-window__prev,
			.v-window__next {
				top: calc(100% - #{gutter-size(11)});
				z-index: zindex(level2);
				margin: 0;
				background: none;
				.v-btn {
					color: $primary-dark;
					svg {
						width: 32px !important;
						height: 32px !important;
						font-size: 32px !important;
					}
				}
			}
		}
		&__item-card {
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%;
			background: $gray-100;
		}
		// &__card-image {
		// 	//height: 100%;
		// }
		&__card-content {
			display: flex;
			flex-direction: column;
			height: 100%;
			@include breakpoint($sm) {
				padding: gutter-size(4);
			}
		}

		&__card-title {
			margin-bottom: 0;
			word-break: break-word;
		}
		&__card-text {
			margin-bottom: auto;
			padding-bottom: 0;
			font-size: $font-size-root;
			line-height: $line-height-root;
			word-break: break-word;
		}
		&__card-button {
			justify-content: flex-start;
			margin-top: auto;
			padding-left: gutter-size(4) !important;
			color: $primary-dark;
		}
		.v-carousel__item {
			overflow: visible;
		}
		.v-carousel__controls {
			justify-content: center;
			&__item {
				margin: 0;
				&:before {
					display: none;
				}
				svg {
					width: gutter-size(4) !important;
					height: gutter-size(4) !important;
					color: $gray-100;
					font-size: $font-size-root !important;
					border: 2px solid $primary-dark;
					border-radius: 50%;
					opacity: 1;
				}
			}
			.v-item--active {
				svg {
					color: $primary-dark;
					background-color: $primary-dark;
				}
			}
		}
	}
}
