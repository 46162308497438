$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-form-location {
		&__location {
			font-weight: $font-weight-regular !important;
			.v-btn__content {
				line-height: $line-height-root !important;
			}
			.v-icon {
				color: $primary-dark;
				font-size: $font-size-root;
			}
		}
		&__card {
			padding: gutter-size(4) 0;
			overflow: hidden;
			@include breakpoint($sm) {
				padding: 0 0 gutter-size(8);
			}
		}
		&__close {
			position: absolute;
			width: 100%;
			text-align: right;
			@include breakpoint($sm) {
				position: relative;
			}
		}
		&__close-icon {
			height: 100%;
			color: $primary-dark;
			font-size: $font-size-H4-max;
			@include breakpoint($sm) {
				font-size: $font-size-H3-max;
			}
		}
		&__icon {
			height: 100%;
			color: $primary-dark;
			font-size: $font-size-H3-max;
		}
		&__title {
			@include h4;
			display: block;
			padding-bottom: gutter-size(6);
			font-weight: $font-weight-bold;
		}
		&__text {
			padding-bottom: gutter-size(6);
		}
		&__form {
			flex-wrap: wrap;
			padding: 0 16px;
		}
		&__label {
			display: block;
			width: 100%;
			font-weight: $font-weight-bold;
		}
		&__input {
			margin-bottom: gutter-size(6);
			font-size: $font-size-root;
			background: $gray-100;
			.v-label {
				color: $gray-500;
				font-size: $font-size-root;
			}
			.v-input__slot {
				margin-bottom: 0;
				background-color: $gray-100;
			}
			.v-text-field__details {
				display: none;
			}

			input::placeholder {
				line-height: normal;
			}

			@include breakpoint($sm) {
				margin-right: gutter-size(6);
				margin-bottom: 0;
			}
		}
		&__button {
			width: 100%;
			max-height: gutter-size(12);
			@include breakpoint($sm) {
				width: auto;
			}
			.v-btn__content {
				line-height: 1;
			}
		}
		&__snackbar {
			.v-snack__wrapper {
				min-width: auto;
				background: transparent;
				box-shadow: none;
				@include breakpoint($sm) {
					min-width: 600px;
				}
				@include breakpoint($md) {
					min-width: 800px;
				}
			}
		}
		&__alert {
			width: 100%;
			background-color: $gray-100;
			border-radius: $border-radius-root;
			.v-alert__icon {
				color: $primary-dark !important;
			}
			.v-alert__border {
				border-color: $primary-dark !important;
			}
			.v-alert__dismissible {
				svg {
					color: $primary-dark !important;
				}
			}
		}
		&__alert-title {
			margin: 0;
			color: $primary-dark;
		}
		&__link {
			color: $primary-dark;
			text-decoration: underline;
		}
	}
}
