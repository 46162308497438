/* vuetify */

// @import "~vuetify/src/styles/styles.sass";

// placeholder images
.v-image__image--preload {
	filter: unset;
}

.v-input {
	border-radius: 0;
}