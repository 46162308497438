$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-cart-line-items {
		&__title {
			@include h3;
			margin-bottom: gutter-size(4);
		}
		&__header {
			display: flex;
			flex-direction: row;
			padding: gutter-size(2);
			list-style: none;
			border: 1px solid transparent;
			li {
				display: block;
				padding: 0 gutter-size(2);
				color: $accent;
				font-size: $font-size-xsmall;
				&:nth-of-type(1) {
					position: relative;
					left: -#{gutter-size(4)};
					width: 50%;
				}
				&:nth-of-type(2) {
					width: 12%;
				}
				&:nth-of-type(3) {
					width: 115px;
				}
			}
		}
		&__item {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-bottom: gutter-size(5);
			padding: gutter-size(2);
			border: 1px solid $border-color;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
			> div {
				padding: gutter-size(2);
			}
		}
		&__details {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
		}
		&__image {
			width: 75px;
			height: 75px;
			margin-right: gutter-size(4);
			border-radius: $border-radius-root;
			opacity: $opacity-base;
			transition: opacity $timing-fastest $easing;
			#{$interact} {
				opacity: $opacity-lots;
			}
		}
		&__name {
			margin-bottom: 0;
			margin-bottom: gutter-size(4);
			color: $accent;
			font-weight: $font-weight-bold;
			font-size: $font-size-root;
			line-height: $line-height-sm;
			transition: color $timing-fastest $easing;
			#{$interact} {
				color: $primary;
			}
		}
		&__properties {
			padding: 0;
			color: $accent;
			list-style: none;
			li {
				margin: gutter-size(1) 0;
			}
		}
		&__price {
			color: $accent;
		}
		&__quantity {
			width: calc(100% - 91px);
		}
		&__input {
			width: 115px;
			.v-icon {
				height: 16px;
				color: $primary;
				font-size: 16px;
			}
			.v-input__slot {
				margin: 0;
				padding: 0 gutter-size(1);
				border: 1px solid $border-color !important;
				border-radius: $border-radius-root;
				&:before {
					display: none;
				}
			}
			.v-input__append-inner,
			.v-input__prepend-inner {
				align-self: center;
				margin-top: 0;
				.v-icon {
					-webkit-appearance: unset;
				}
			}
			input {
				margin: 0;
				font-weight: $font-weight-semibold;
				text-align: center;
			}
			/* stylelint-disable */
			input[type="number"]::-webkit-inner-spin-button,
			input[type="number"]::-webkit-outer-spin-button {
				margin: 0;
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
			}
			input[type="number"] {
				-moz-appearance: textfield;
			}
			/* stylelint-enable */
			.v-messages__message {
				text-align: center;
			}
		}
		&__total {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			width: calc(100% - 91px);
			color: $primary;
			font-weight: $font-weight-bold;
			font-size: $font-size-xlarge;
		}
		&__remove {
			min-width: unset;
			font-weight: $font-weight-regular !important;
			font-size: $font-size-xsmall !important;
			letter-spacing: 0;
			text-transform: unset;
		}
		@include breakpoint($sm) {
			&__item {
				flex-direction: row;
				align-items: center;
			}
			&__details {
				width: 50%;
			}
			&__properties {
				display: flex;
				li {
					margin: 0;
					&:first-of-type {
						padding-right: gutter-size(4);
					}
					&:last-of-type:not(:only-of-type) {
						padding-left: gutter-size(4);
						border-left: 1px solid $border-color;
					}
				}
			}
			&__price {
				width: 12%;
			}
			&__quantity {
				width: 115px;
			}
			&__total {
				flex-grow: 1;
				width: auto;
			}
		}
		@include breakpoint($md) {
			&__image {
				width: 96px;
				height: 96px;
			}
		}
	}
}
