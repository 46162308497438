$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-carousel-media {
		position: relative;
		height: 100%;
		&__carousel {
			min-height: 200px;
			background-color: $gray-100;
			&--center {
				.v-carousel__controls {
					justify-content: center;
				}
			}
			&--left {
				.v-carousel__controls {
					justify-content: flex-start;
				}
			}
			&--right {
				.v-carousel__controls {
					justify-content: flex-end;
				}
			}
		}
		.v-window__prev,
		.v-window__next {
			display: none;
			background: none;
			.v-btn {
				color: $primary;
				svg {
					width: 24px !important;
					height: 24px !important;
					font-size: 24px !important;
				}
			}
		}
		.v-carousel__controls {
			.v-item-group {
				margin: 0 gutter-size(2);
			}
			&__item {
				margin: 0;
				&:before {
					display: none;
				}
				svg {
					width: 16px !important;
					height: 16px !important;
					color: transparent;
					font-size: $font-size-root !important;
					border: 2px solid $white;
					border-radius: 50%;
					opacity: 1;
				}
			}
			.v-item--active {
				svg {
					color: $white;
					background-color: $white;
				}
			}
		}
		&__item--right {
			.scl-carousel-media__content {
				text-align: right;
			}
			.scl-carousel-media__btn {
				float: right;
			}
			@include breakpoint($sm) {
				.scl-carousel-media__content {
					padding: 80px 8% 0 30%;
				}
			}
			@include breakpoint($lg) {
				.scl-carousel-media__content {
					padding: 80px 10% 0 35%;
				}
			}
		}
		&__row {
			height: 100%;
			.v-lazy {
				height: 100%;
			}
		}
		&__card {
			display: flex;
			height: 100%;
			background-color: $white;
			aspect-ratio: 16/8;
		}
		&__media {
			width: 100%;
			.v-responsive__content {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
			}
		}
		&__media-video {
			position: absolute;
			right: 0;
			left: 0;
			height: 100%;
			object-fit: cover;
		}
		&__content {
			position: relative;
			align-self: flex-start;
			width: 100%;
			height: 100%;
			padding-top: 80px;
			.v-card__actions {
				display: block;
				padding: 0;
			}
		}
		&__title {
			display: block;
			color: $white;
			font-weight: $font-weight-bold;
			word-break: break-word;
		}
		&__subtitle {
			margin-top: 0;
			padding: 0;
			color: $white;
			font-weight: $font-weight-bold;
			font-size: $font-size-root;
			line-height: $line-height-root;
		}
		&__text {
			padding: 0;
			color: $white;
			font-size: $font-size-root;
			line-height: $line-height-root;
		}
		&__btn {
			margin-top: gutter-size(4);
			color: $white;
			font-weight: $font-weight-semibold;
			text-transform: unset;
			#{$interact} {
				color: $white;
				background-color: $primary-dark !important;
				border-color: $primary-dark !important;
			}
		}
		@include breakpoint($sm) {
			.v-window__prev,
			.v-window__next {
				display: block;
			}
			&__content {
				padding: 80px 30% 0 8%;
			}
		}
		@include breakpoint($lg) {
			&__content {
				padding: 80px 35% 0 10%;
			}
			&__btn {
				padding: gutter-size(4) gutter-size(7);
			}
		}
	}
}
