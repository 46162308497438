$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-facets {
		position: relative;

		&__accordion {
			margin-bottom: gutter-size(2);
			border: 1px solid $border-color;
		}
		&__header {
			min-height: 0;
			padding: 16px;
		}
		&__title {
			width: 100%;
			color: $primary-dark;
		}
		&__content {
			padding: 0 0 gutter-size(2);
		}
		&__check {
			@include clearfix;
			width: 100%;
			&:first-of-type {
				margin-top: 0;
				padding-top: gutter-size(6);
				border-top: 1px solid $border-color;
			}
			label {
				color: $accent;
				font-size: $font-size-root;
			}
			svg {
				color: $gray-500;
			}
		}
		&__clear {
			font-size: $font-size-small;
		}
		&__accordion-icon {
			width: gutter-size(4);
			height: gutter-size(4);
			color: $primary-dark;
			text-align: right;
			&--minus {
				display: none;
			}
		}
		.v-expansion-panel-header--active {
			color: $primary-dark;
			.scl-product-facets__accordion-icon--minus {
				display: block;
			}
			.scl-product-facets__accordion-icon--plus {
				display: none;
			}
		}
	}
}
