$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-auth-activation-form {
		&__header {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&__image {
			margin-bottom: gutter-size(3);
		}
		&__title {
			@include breakpoint($sm) {
				margin-bottom: gutter-size(10);
			}
		}
		&__form {
			padding: gutter-size(8) gutter-size(6) 0;
			border: 1px solid $border-color;
			border-radius: $border-radius-root;
		}
		&__link {
			display: block;
			margin-top: gutter-size(1);
			text-decoration: underline;
		}
		&__error-message {
			color: $error;
		}
	}
}
