$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-card {
		position: relative;
		height: 100%;
		border: 1px solid $border-color;
		background: $secondary-dark;
		.v-lazy {
			height: 100%;
		}
		&__card {
			height: 100%;
			background: $gray-200;
			border-radius: 0;
			.v-image {
				background: $white;
			}
		}
		&__content {
			padding: gutter-size(6);
			.v-card__title {
				padding: 0;
			}
		}
		&__title {
			margin-bottom: 0;
			font-size: $font-size-xlarge;
			word-break: break-word;
			color: $primary-dark;
			font-weight: $font-weight-black;
		}
		&__sku {
			padding: gutter-size(2) 0;
			color: $accent;
			font-weight: $font-weight-black;
		}
		&__text {
			flex: 1 0 auto;
			padding: 0;
			font-size: $font-size-root;
			line-height: $line-height-root;
			color: $gray-600;
		}
		&__price {
			position: absolute;
			right: gutter-size(4);
			bottom: gutter-size(4);
			left: gutter-size(4);
			display: flex;
		}
		&__price-item {
			padding-top: gutter-size(5);
			&:first-child {
				padding-right: gutter-size(5);
			}
			span {
				font-weight: $font-weight-semibold;
				font-size: $font-size-xlarge;
			}
			small {
				display: block;
				font-size: $font-size-xsmall;
			}
			&--member {
				span {
					text-decoration: line-through;
				}
			}
		}
		&__stock {
			position: absolute;
			top: gutter-size(2.5);
			right: gutter-size(-2);
			z-index: zindex(level2);
			padding: gutter-size(6);
			color: $white;
			font-weight: $font-weight-regular;
			text-transform: initial;
			background: $error;
		}
		#{$interact} {
			.scl-product-card__card {
				transition: all $easing $timing-fast;
				height: calc(100% - 8px);
			}
			.scl-product-card__title {
				transition: all $easing $timing-fast;
				color: $secondary-dark;
			}
		}
	}
}
