$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-tabs {
		position: relative;
		.v-tabs {
			position: relative;
			z-index: zindex(level1);
			.v-tabs-bar__content {
				border-bottom: 1px solid $border-color;
			}
			.v-tabs-slider-wrapper {
				display: none;
			}
		}
		&__overflow-hide {
			.v-slide-group__prev {
				display: none;
			}
		}
		&__item {
			position: relative;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			height: 100%;
			margin: 0;
			color: $accent;
			background-color: $gray-100;
			border-top: 1px solid $border-color;
			border-left: 1px solid $border-color;
			transition: background-color $timing-fast $easing;
			#{$interact} {
				background-color: $gray-200;
			}
			&:before {
				content: none;
			}
			&:first-of-type {
				border-top-left-radius: 3px;
			}
			&:last-of-type {
				border-right: 1px solid $border-color;
				border-top-right-radius: 3px;
			}
			.v-image {
				margin-right: gutter-size(3);
				margin-bottom: 0;
			}
		}
		&__item-title {
			position: relative;
			color: $accent-light;
			font-weight: $font-weight-semibold;
			font-size: $font-size-root;
			font-family: $body-font-family;
			letter-spacing: 0;
			text-transform: initial;
		}
		.v-tab--active {
			background-color: $white;
			box-shadow: 1px 3px 0 -1px $white, 0 0 3px $gray-100;
			#{$interact} {
				background-color: $white;
			}
			.scl-tabs__item-title {
				color: $primary-dark;
			}
		}
		&__item-content {
			width: 100%;
			margin: 0;
			padding: gutter-size(3);
			border: 1px solid $border-color;
			border-top: none;
			border-radius: $border-radius-root;
			border-top-left-radius: 0;
			box-shadow: $shadow-box;
		}
		@include breakpoint($sm) {
			&__item-content {
				padding: gutter-size(8);
			}
		}
	}
}
