$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-search-list-item {
		padding-top: gutter-size(6);
		border-top: 1px solid $gray-300;
		&__title {
			margin-bottom: gutter-size(2);
			font-weight: $font-weight-semibold;
		}
		&__description {
			margin-bottom: gutter-size(3);
		}
		&__cta {
			display: flex;
			align-items: center;
			color: $primary-dark;
			font-weight: $font-weight-semibold;
			line-height: gutter-size(6);
			transition: color $timing-fastest $easing;
			img {
				position: relative;
				width: auto;
				height: gutter-size(4.5);
				margin-left: gutter-size(2);
			}
			#{$interact} {
				color: $primary;
			}
		}
	}
}
