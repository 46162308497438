$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
/*!
   app.scss
*/

// order of css matters
// this file outlines the structure and order of SCSS used in this project, it is modelled after ITCSS
// due to the way vue-cli and webpack are configured, we import css in multiple locations
// these two files call the css used in the project: vue.config.js > main.js


// --------------------------------------------------
// 1. helpers
// --------------------------------------------------
// helpers are imported in [vue.config.js] so they're available globally
// these files should NEVER generate any css, only variables, mixins, functions, animations etc

// @import "settings/variables";
// @import "tools/mixins";


// --------------------------------------------------
// 2. libraries
// --------------------------------------------------
// load vendor libraries next
// we also override any library variables if we can't do it above

@import "libraries/vuetify";
@import "~animate.css/animate";


// --------------------------------------------------
// 3. generic
// --------------------------------------------------
// styling of general html tags and global app layout elements

@import "generic/generic";
@import "generic/typography";

// --------------------------------------------------
// 4. elements
// --------------------------------------------------
// any objects/elements which are component agnostic; they can could be used anywhere

@import "elements/button";
@import "elements/animate";


// --------------------------------------------------
// 5. components
// --------------------------------------------------
// loaded in their individual component files


// --------------------------------------------------
// 6. utilities
// --------------------------------------------------
// should be loaded last, with !important flags
@import "utilities/utilities";
@import "utilities/position";
@import "utilities/custom-overrides";
