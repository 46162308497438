$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-step-summary {
		position: relative;
		margin-top: gutter-size(4);
		&__container {
			display: flex;
			flex-wrap: wrap;
		}
		&__item {
			display: flex;
			flex: 1 1 100%;
			flex-wrap: wrap;
			align-items: flex-start;
			margin-bottom: gutter-size(4);
			padding: gutter-size(6);
			border: 2px solid $primary-dark;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
			&__price {
				display: block;
				border: 2px solid $border-color;
				border-bottom: 8px solid $secondary-dark;
			}
			&__price-heading {
				margin-bottom: gutter-size(2);
				color: $accent-light;
				font-size: $font-size-H4-max;
			}
			&__price-value {
				margin: 0;
				span {
					font-weight: $font-weight-regular;
					font-size: $font-size-xsmall;
				}
			}
			@include breakpoint($sm) {
				padding: gutter-size(9);
			}
		}
		&__row {
			display: flex;
			flex-wrap: nowrap;
			width: 100%;
		}
		&__col {
			width: 100%;
		}
		&__title {
			width: 100%;
			margin-bottom: gutter-size(2);
			color: $accent;
			font-weight: $font-weight-bold;
		}
		&__text {
			margin: 0;
		}

	}
}
