$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-sc-forms {
		padding: gutter-size(7.5);
		background-color: $white;
		a {
			color: $link-color;
			text-decoration: $link-decoration;
			#{$interact} {
				color: $link-hover-color;
				text-decoration: $link-hover-decoration;
			}
		}
		fieldset,
		.fieldset {
			margin-top: gutter-size(12);
			border: 0;
		}
		legend,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			position: relative;
			display: block;
			width: 100%;
			margin-bottom: gutter-size(8);
			font-size: $font-size-root;
			span {
				position: relative;
				z-index: 1;
				padding-right: gutter-size(4);
				background-color: $white;
			}
			&:before {
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				height: 2px;
				background-color: $secondary;
				transform: translateY(50%);
				content: "";
			}
		}
		label {
			@include clearfix;
			display: block;
			margin: gutter-size(2) 0;
			font-weight: $font-weight-regular;
			cursor: pointer;
			span {
				margin-left: gutter-size(4);
				font-weight: $font-weight-regular;
			}
			input {
				float: left;
			}
		}
		label[for] {
			display: block;
			margin: 0 0 gutter-size(2);
			font-weight: $font-weight-semibold;
			cursor: default;
		}
		input,
		select,
		textarea {
			display: block;
			width: 100%;
			height: gutter-size(12);
			margin-bottom: gutter-size(6);
			padding: 0 gutter-size(3);
			color: $black;
			line-height: gutter-size(12);
			border: 1px solid $border-color;
			outline: none;
			&:-webkit-autofill {
				background-color: transparent !important;
				-webkit-box-shadow: 0 0 0 50px $gray-100 inset;
			}
			&::placeholder {
				color: $gray-500;
				opacity: 1;
			}
			#{$interact} {
				color: $gray-500;
				border-color: $border-color;
			}
			&:focus {
				&:valid {
					border: 1px solid $success;
					box-shadow: 0 0 2px 1px rgba($success, 0.2);
				}
			}
			&:invalid {
				box-shadow: none;
			}
			&.input-validation-error {
				border: 1px solid $error;
				box-shadow: 0 0 2px 1px rgba($error, 0.2);
			}
			&:disabled {
				opacity: $opacity-half;
				pointer-events: none;
			}
		}
		input[type="date"] {
			padding-right: gutter-size(9) !important;
			background: $gray-100
				url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhciIgZGF0YS1pY29uPSJjYWxlbmRhci1hbHQiIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jYWxlbmRhci1hbHQgZmEtdy0xNCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBmaWxsPSIjMDA1NzNkIiBkPSJNMTQ4IDI4OGgtNDBjLTYuNiAwLTEyLTUuNC0xMi0xMnYtNDBjMC02LjYgNS40LTEyIDEyLTEyaDQwYzYuNiAwIDEyIDUuNCAxMiAxMnY0MGMwIDYuNi01LjQgMTItMTIgMTJ6bTEwOC0xMnYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTk2IDB2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgOTZ2LTQwYzAtNi42LTUuNC0xMi0xMi0xMmgtNDBjLTYuNiAwLTEyIDUuNC0xMiAxMnY0MGMwIDYuNiA1LjQgMTIgMTIgMTJoNDBjNi42IDAgMTItNS40IDEyLTEyem0tOTYgMHYtNDBjMC02LjYtNS40LTEyLTEyLTEyaC00MGMtNi42IDAtMTIgNS40LTEyIDEydjQwYzAgNi42IDUuNCAxMiAxMiAxMmg0MGM2LjYgMCAxMi01LjQgMTItMTJ6bTE5MiAwdi00MGMwLTYuNi01LjQtMTItMTItMTJoLTQwYy02LjYgMC0xMiA1LjQtMTIgMTJ2NDBjMCA2LjYgNS40IDEyIDEyIDEyaDQwYzYuNiAwIDEyLTUuNCAxMi0xMnptOTYtMjYwdjM1MmMwIDI2LjUtMjEuNSA0OC00OCA0OEg0OGMtMjYuNSAwLTQ4LTIxLjUtNDgtNDhWMTEyYzAtMjYuNSAyMS41LTQ4IDQ4LTQ4aDQ4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoMTI4VjEyYzAtNi42IDUuNC0xMiAxMi0xMmg0MGM2LjYgMCAxMiA1LjQgMTIgMTJ2NTJoNDhjMjYuNSAwIDQ4IDIxLjUgNDggNDh6bS00OCAzNDZWMTYwSDQ4djI5OGMwIDMuMyAyLjcgNiA2IDZoMzQwYzMuMyAwIDYtMi43IDYtNnoiPjwvcGF0aD48L3N2Zz4=")
				no-repeat calc(100% - #{gutter-size(2)}) 50%;
			background-size: 24px 24px;
			&::-webkit-datetime-edit-month-field,
			&::-webkit-datetime-edit-day-field,
			&::-webkit-datetime-edit-year-field {
				color: rgba($black, 0.8);
			}
			&::-webkit-calendar-picker-indicator {
				display: none;
			}
		}
		input[type="checkbox"],
		input[type="radio"] {
			width: 22px;
			height: 22px;
			margin-right: gutter-size(5);
			padding: 0;
			background: $white;
			border: 2px solid $border-color;
			outline: 0;
			cursor: pointer;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
		input[type="checkbox"]:checked {
		}

		input[type="checkbox"]:hover {
			filter: brightness(90%);
		}
		input[type="checkbox"]:after {
			position: relative;
			top: 16%;
			left: 38%;
			display: none;
			width: 7px;
			height: 13px;
			border: solid #fff;
			border-width: 0 3px 3px 0;
			transform: rotate(45deg);
			content: "";
		}
		input[type="checkbox"]:checked:after {
			display: block;
		}
		input[type="radio"] {
			border-radius: 50%;
		}
		input[type="radio"]:checked {
			background: $white;
			border: 2px solid $primary;
		}

		input[type="radio"]:hover {
			filter: brightness(90%);
		}
		input[type="radio"]:after {
			position: relative;
			top: 4px;
			left: 4px;
			display: none;
			width: 10px;
			height: 10px;
			background: $primary;
			border-radius: 50%;
			content: "";
		}
		input[type="radio"]:checked:after {
			display: block;
		}
		select {
			padding-right: gutter-size(9) !important;
			background: $gray-100
				url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjYXJldC1kb3duIiBjbGFzcz0ic3ZnLWlubGluZS0tZmEgZmEtY2FyZXQtZG93biBmYS13LTEwIiByb2xlPSJpbWciIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDMyMCA1MTIiPjxwYXRoIGZpbGw9IiMwMDU3M2QiIGQ9Ik0zMS4zIDE5MmgyNTcuM2MxNy44IDAgMjYuNyAyMS41IDE0LjEgMzQuMUwxNzQuMSAzNTQuOGMtNy44IDcuOC0yMC41IDcuOC0yOC4zIDBMMTcuMiAyMjYuMUM0LjYgMjEzLjUgMTMuNSAxOTIgMzEuMyAxOTJ6Ij48L3BhdGg+PC9zdmc+DQo=")
				no-repeat calc(100% - #{gutter-size(2)}) 50%;
			background-size: 24px 24px;
			appearance: none;
		}
		textarea {
			height: 160px;
		}
		.field-validation-valid,
		.field-validation-error {
			display: block;
			margin-bottom: gutter-size(2);
			color: #6e7071;
			font-size: $font-size-small;
		}
		.field-validation-error {
			color: $error;
		}
		button,
		input[type="button"],
		input[type="submit"] {
			display: block;
			width: 100%;
			height: 64px;
			margin: gutter-size(8) 0 gutter-size(5);
			padding: 0 gutter-size(13);
			color: $white;
			font-size: $font-size-root;
			line-height: 64px;
			background-color: $primary;
			border-color: $primary;
			transition: all $easing $timing-faster;
			#{$interact} {
				background-color: $secondary;
				border-color: $secondary;
				outline: 0;
				cursor: pointer;
			}
			&:disabled {
				opacity: $opacity-half;
				pointer-events: none;
			}
		}
		input::-webkit-file-upload-button {
			margin-right: gutter-size(2);
			padding: 0 gutter-size(3);
			background: $primary;
			color: $white;
			border: none;
		}
		input[type="file"]{
			padding-left: 0;
		}
		@include breakpoint($sm) {
			legend,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				font-size: $font-size-xlarge;
			}
			label {
				@include clearfix;
				span {
					float: right;
				}
			}
			input,
			select,
			textarea {
				padding: 0 gutter-size(4);
			}
			button,
			input[type="button"],
			input[type="submit"] {
				width: auto;
			}
		}
		// Vuetify inputs
		&__form {
			&-label {
				@include clearfix;
				display: block;
				margin-bottom: gutter-size(2);
				color: $accent;
				font-weight: $font-weight-semibold;
				font-size: $font-size-root;
				line-height: $line-height-sm;
				&.is-optional {
					padding-right: 70px;
					&:before {
						position: relative;
						right: -70px;
						float: right;
						color: #575f65;
						font-weight: $font-weight-regular;
						font-size: $font-size-xsmall;
						line-height: 20px;
						content: "Optional";
					}
				}
				&.is-disabled {
					opacity: $opacity-half;
				}
			}
			&-title {
				position: relative;
				display: block;
				margin-bottom: gutter-size(6);
				font-weight: $font-weight-bold;
				font-size: 20px;
				span {
					position: relative;
					padding-right: gutter-size(4);
					background-color: $white;
				}
				&:before {
					position: absolute;
					top: 50%;
					right: 0;
					left: 0;
					height: 2px;
					background-color: $secondary;
					transform: translateY(-50%);
					content: "";
				}
			}
			&-input {
				fieldset {
					border-color: $border-color;
				}
				input {
					color: $accent;
					font-weight: $font-weight-semibold;
					font-size: $font-size-root;
					line-height: $line-height-root;
				}
				.v-input__slot:hover {
					fieldset {
						border-color: $border-hover-color;
					}
				}
				.v-text-field__details {
					padding: 0;
				}
				.v-messages__message {
					color: #575f65;
					font-weight: $font-weight-regular;
					font-size: $font-size-xsmall;
				}
				&.error--text {
					fieldset {
						border: 1px solid $error;
						box-shadow: 0 0 2px 1px rgba($error, 0.2);
					}
					input {
						color: $accent !important;
					}
				}
				.error--text {
					.v-messages__message {
						color: $error;
					}
				}
				&.v-input--is-disabled {
					opacity: $opacity-half;
				}
				.v-icon {
					color: $primary-dark;
					font-size: $font-size-xlarge;
				}
			}
			&-selection {
				label {
					margin-left: gutter-size(1);
					color: $accent;
					font-size: $font-size-root;
					line-height: $line-height-root;
				}
			}
			&-btn {
				letter-spacing: 0;
				text-transform: unset;
				.v-btn__content {
					justify-content: space-between;
				}
				&.v-btn--outlined {
					border-width: 2px;
				}
				&.v-btn--disabled {
					color: $white !important;
					background-color: $gray-500 !important;
					.v-icon {
						color: $white !important;
					}
				}
			}
			.fa-chevron-down {
				color: $primary-dark;
			}
			@include breakpoint($sm) {
				&-input {
					&.is-narrow {
						width: 50%;
					}
				}
			}
		}
	}
	// dark variant
	.scl-sc-forms--dark {
		background-color: $accent;
		legend {
			color: $white;
			span {
				background-color: $accent;
			}
		}
		label {
			color: $white;
		}
		.field-validation-valid {
			color: $white;
		}
	}
}
