$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-article-introtext {
		position: relative;
		font-size: $font-size-H4-max;
	}
	.scl-article-date {
		position: relative;
		color: $accent-light;
		font-size: $font-size-root;
	}
}
