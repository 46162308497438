$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-card-video {
		position: relative;

		&__image {
			overflow: visible;
			border-radius: $border-radius-root;
		}
		&__video {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		&__overlay {
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: "";
			}
		}
		&__overlay--little {
			&:after {
				background: rgba($black, $opacity-little);
				background: linear-gradient(70deg, rgba($black, 1) 0%, rgba($accent, $opacity-little) 100%);
			}
		}
		&__overlay--half {
			&:after {
				background: rgba($black, $opacity-half);
				background: linear-gradient(70deg, rgba($black, 1) 0%, rgba($accent, $opacity-half) 100%);
			}
		}
		&__overlay--lots {
			&:after {
				background: rgba($black, $opacity-lots);
				background: linear-gradient(70deg, rgba($black, 1) 0%, rgba($accent, $opacity-lots) 100%);
			}
		}
		&__overlay--full {
			&:after {
				background: $black;
			}
		}
		&__text {
			position: relative;
			z-index: zindex(level1);
			padding: gutter-size(6) gutter-size(8) 0;
			font-weight: $font-weight-semibold;
			text-align: left;
		}
		&__headline {
			@include h3;
			margin: gutter-size(2) 0 gutter-size(4);
			line-height: $line-height-sm;
		}
		&__button {
			color: $white;
			background-color: $primary;
			box-shadow: $shadow-btn;
			#{$interact} {
				color: $white;
				box-shadow: none;
			}
		}
		&__ribbon {
			position: absolute;
			top: gutter-size(4);
			right: gutter-size(-2);
			z-index: zindex(level1);
			padding: gutter-size(3.5) gutter-size(6);
			color: $accent;
			font-weight: $font-weight-semibold;
			background-color: $secondary;
			border-radius: $border-radius-root;
			&--primary {
				background-color: $primary;
			}
		}
		.v-card {
			height: 100%;
		}
		.v-image {
			height: 100%;
		}
		.white--text {
			h3 {
				color: $white;
			}
		}
	}
}
