$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-video {
		position: relative;
		&__caption {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: gutter-size(3);
			padding: gutter-size(2) 0;
			color: $accent;
			font-size: $font-size-small;
			border-bottom: 2px solid $secondary;
			a {
				width: 100%;
				color: $accent;
				text-decoration: underline;
				&:hover {
					color: $accent;
				}
			}
			@include breakpoint($md) {
				font-size: $font-size-root;
				a {
					width: auto;
				}
			}
		}
		&__video--embedded {
			width: 100%;
		}
		&__video--vimeo,
		&__video--youtube {
			position: relative;
			&:before {
				display: block;
				padding-top: 56.25%;
				content: '';
			}
			iframe {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
	}
}
