/*! button */

#app {
	.btn {
		align-items: center;
		justify-content: center;
		height: auto;
		padding: gutter-size(4) gutter-size(6);
		font-weight: $font-weight-semibold;
		letter-spacing: 0;
		white-space: normal;
		text-transform: none;
		text-decoration: none;
		border-radius: 0;
		box-shadow: none;
		#{$interact} {
			text-decoration: none;
			.btn__icon-link {
				right: gutter-size(-1);
			}
		}
		&:before {
			content: none;
		}
		.v-btn__content {
			width: 100%;
			line-height: $line-height-sm;
			text-align: left;
			text-decoration: none;
			#{$interact} {
				text-decoration: none;
			}
		}
		&__icon {
			margin-right: gutter-size(4);
			color: inherit;
			font-weight: $font-weight-regular;
			font-size: $font-size-root;
		}
		&__icon-link {
			right: 0;
			margin-left: gutter-size(4);
			color: inherit;
			font-size: $font-size-root;
			transition: right $easing $timing-faster;
		}
	}
	.btn--x-small {
		padding: gutter-size(1.5) gutter-size(4);
	}
	.btn--small {
		padding: gutter-size(2) gutter-size(4);
	}
	.btn--large {
		padding: gutter-size(4) gutter-size(6);
		@include breakpoint($sm) {
			padding: gutter-size(4.5) gutter-size(7.5);
		}
	}
	.btn-primary {
		color: $white;
		background-color: $primary;
		border: 2px solid $primary;
		transition: all $easing $timing-faster;
		#{$interact} {
			color: $white;
			background-color: $secondary;
			border-color: $secondary;
		}
	}
	.btn-outlined {
		color: $primary;
		background-color: transparent;
		border: 2px solid $primary;
		transition: all $easing $timing-faster;
		#{$interact} {
			color: $secondary;
			border-color: $secondary;
		}
	}
	.btn-link {
		justify-content: space-between;
		padding-left: 0;
		color: $primary;
		background-color: transparent;
		box-shadow: none;
		transition: all $easing $timing-faster;
		#{$interact} {
			color: $secondary;
		}
		.v-btn__content {
			flex-shrink: 1;
		}
	}

	.v-btn--disabled.btn-primary {
		color: $white !important;
		background-color: rgba($accent, $opacity-half) !important;
		border-color: rgba($accent, 0);
	}
	.v-btn--disabled.btn-outlined {
		background-color: transparent !important;
		border-color: rgba($accent, $opacity-half);
	}
	.theme--dark {
		.btn-primary {
			color: $primary;
			background: $white;
			border-color: $white;
			#{interact} {
				color: $white;
				background-color: $secondary;
				border-color: $secondary;
			}
		}
		.btn-outlined {
			color: $white;
			border-color: $white;
			#{$interact} {
				color: $secondary;
				border-color: $secondary;
			}
		}
		.btn-link {
			color: $white;
			#{$interact} {
				color: $secondary;
			}
		}
	}
}
