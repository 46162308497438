$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-card-vertical {
		position: relative;
		display: flex;
		flex-direction: column;
		&__auto {
			height: auto;
		}
		&__fill-height {
			height: 100%;
		}
		&__card {
			display: flex;
			flex-direction: column;
			height: 100%;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
		}
		&__title {
			font-weight: $font-weight-bold;
			font-size: $font-size-xlarge;
			word-break: break-word;
		}
		&__content {
			display: flex;
			flex-direction: column;
			height: 100%;
		}
		&__text {
			font-weight: $font-weight-regular;
			font-size: $font-size-root;
			p {
				margin-bottom: 0;
				word-break: break-word;
			}
		}
		&__icon {
			margin-left: gutter-size(2);
			font-size: $font-size-root;
		}
		&__card-actions {
			display: block;
			margin-top: auto;
			padding-left: gutter-size(4);
		}
		.v-btn {
			text-transform: unset;
		}
		.theme--dark {
			.scl-card-vertical__title {
				color: $white;
			}
		}
	}
}
