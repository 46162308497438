/*! typography */

html {
	font-size: $font-size-root;
}
body {
	color: $body-color;
	background: #E5E5E5;
	font-family: $body-font-family;
	line-height: $line-height-root;
	text-rendering: optimizeLegibility;
}

#app {
	::selection {
		color: darken($primary, 20%);
		text-shadow: none;
		background-color: rgba($primary, 0.1);
	}

	// headings
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	form legend {
		margin-bottom: $font-size-root * $line-height-root;
		color: $headings-color;
		font-weight: $font-weight-black;
		font-family: $heading-font-family;
		line-height: $line-height-sm;
		sup {
			font-size: 50%;
		}
	}
	h1 {
		@include h1;
	}
	h2 {
		@include h2;
	}
	h3 {
		@include h3;
	}
	h4 {
		@include h4;
	}
	h5 {
		@include h5;
	}
	h6 {
		@include h6;
	}

	a {
		color: $primary-dark;
		text-decoration: inherit;
		#{$interact} {
			outline: 0;
			cursor: pointer;
		}
	}

	p {
		margin-bottom: $font-size-root * $line-height-root;
		line-height: $line-height-root;
	}

	small {
		font-size: $font-size-small;
		&.x-small {
			font-size: $font-size-xsmall;
		}
	}

	strong {
		font-weight: $font-weight-bold;
	}

	em {
		font-size: italic;
	}

	button,
	.v-btn {
		font-weight: $font-weight-semibold;
		font-size: $font-size-root;
		font-family: $body-font-family;
		line-height: $line-height-root;
		letter-spacing: 0;
		.v-icon {
			font-weight: $font-weight-regular;
		}
	}

	label {
		color: $black;
		font-weight: $font-weight-semibold;
		font-size: $font-size-small;
		font-family: $body-font-family;
		line-height: $line-height-root;
	}

	blockquote {
		font-size: 32px;
	}
}
