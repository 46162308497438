$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-training-list-item {
		position: relative;
		height: 100%;
		.v-lazy {
			height: 100%;
		}
		&__item {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding-bottom: gutter-size(6);
			border: 1px solid $border-color;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
			transition: box-shadow $timing-fastest $easing;
			#{$interact} {
				box-shadow: none;
				cursor: pointer;
			}
		}
		&__img {
			opacity: 1;
			transition: opacity $timing-fastest $easing;
			#{$interact} {
				opacity: $opacity-lots;
			}
		}
		&__title {
			margin-bottom: gutter-size(4);
			color: $accent;
			transition: color $timing-fastest $easing;
			#{$interact} {
				color: $primary;
			}
		}
		&__content {
			height: 100%;
			margin-bottom: auto;
			padding: gutter-size(6);
		}
		&__link {
			display: block;
			width: 100%;
			margin-top: auto;
			padding-left: gutter-size(6);
			color: $primary-dark;
			font-weight: $font-weight-bold;
			.btn {
				padding: 0;
			}
		}
		&__list {
			display: flex;
			align-items: center;
			margin-bottom: gutter-size(4);
			padding: 0;
			list-style: none;
		}
		&__label {
			margin-right: gutter-size(2);
			padding-right: gutter-size(2);
			color: $accent-light;
			font-size: $font-size-small;
			line-height: gutter-size(4);
			border-right: 1px solid $accent-lighter;
			&:last-of-type {
				margin-right: 0;
				padding-right: 0;
				border-right: none;
			}
		}
		@include breakpoint($sm) {
			&__title {
				margin-bottom: gutter-size(6);
			}
		}
	}
}
