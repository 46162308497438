$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";











































































































































































































#app {
	.all-components {
		&__wrapper {
			margin: 10vh auto;
		}
		&__list {
			padding: 0;
			column-count: 1;
			list-style: none;
			li {
				margin: gutter-size(1) 0;
			}
			a {
				#{$interact} {
					color: $primary;
				}
			}
		}
		&__name {
			display: block;
			padding: gutter-size(3);
			color: $black;
			background-color: $gray-100;
			transition: all $timing-fastest $easing;
			#{$interact} {
				color: darken($primary, 10%);
				background-color: rgba($primary, 0.1);
			}
		}
		@include breakpoint($sm) {
			&__list {
				column-count: 2;
			}
		}
		@include breakpoint($md) {
			&__list {
				column-count: 3;
			}
		}
		@include breakpoint($lg) {
			&__list {
				column-count: 4;
			}
		}
	}
}
