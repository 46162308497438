$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-cart-promo {
		&__container {
			display: flex;
			flex-direction: row;
			align-items: stretch;
			justify-content: space-between;
			margin: gutter-size(3) 0 gutter-size(4);
		}
		&__input {
			margin: 0;
			padding: 0;
			.v-input__slot {
				margin: 0;
				padding: 0 gutter-size(4);
				border: 1px solid $border-color !important;
				border-radius: $border-radius-root;
				&:before {
					display: none;
				}
			}
			input {
				margin: 0;
				font-weight: $font-weight-semibold;
			}
			&.has-error {
				.v-input__slot {
					border: 1px solid $error !important;
				}
			}
			&.has-promo {
				.v-input__slot {
					border: 1px solid $primary !important;
				}
			}
		}
		&__btn {
			height: auto;
			margin-left: gutter-size(3);
			padding: 0 gutter-size(12);
			text-transform: unset;
		}
		@include breakpoint($sm) {
			float: right;
			width: 405px;
		}
	}
}
