$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-header-profile-menu {
		position: relative;
		z-index: zindex(level4);

		&__button {
			position: relative;
			height: 100%;
			padding: gutter-size(4);
			color: $primary-dark;
			text-align: right;
			text-transform: initial;
			background: transparent;
			border: none;
			border-radius: 0;
			border-top-left-radius: $border-radius-root;
			border-top-right-radius: $border-radius-root;
			box-shadow: none;
			.scl-header__secondary-icon {
				margin: 0;
			}
			&[aria-expanded="true"] {
				background: $gray-100;
				border: 1px solid $border-color;
				border-bottom: none;
				box-shadow: 0 10px 0 $gray-100;
				.scl-header__secondary {
					box-shadow: none;
				}
				.scl-header-profile-menu__button-icon {
					transform: rotate(180deg);
				}
			}
			&:before {
				content: none;
			}
			&-icon {
				height: gutter-size(4);
				color: $primary-dark;
			}
		}
		&__avatar {
			margin-right: gutter-size(1);
			color: $primary-dark;
			border: 2px solid $primary-dark;
		}
		&__wrapper {
			right: 0 !important;
			left: 0 !important;
			z-index: 111 !important;
			width: 100% !important;
			min-width: 100% !important;
			box-shadow: none;
		}
		&__container {
			padding: 0;
		}
		&__header-information {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: gutter-size(6) gutter-size(4);
			background-color: $gray-100;
			h3 {
				margin: 0;
			}
		}
		&__dismiss-button {
			color: $primary-dark;
		}
		&__card {
			border: 1px solid $border-color;
			border-top-right-radius: 0;
			box-shadow: none;
		}
		&__nav-list-link {
			min-height: auto;
			margin-bottom: gutter-size(4);
			padding: 0;
			&:before {
				content: none;
			}
			#{$interact} {
				color: $primary !important;
				cursor: pointer;
			}
			.v-list-item__title {
				white-space: normal;
			}
		}
		@include breakpoint($sm) {
			&__button {
				margin-left: gutter-size(3);
				padding: gutter-size(2);
			}
			&__container {
				padding: gutter-size(3);
			}
		}
	}
}
