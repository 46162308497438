$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-page-size {
		position: relative;

		&__title {
			width: 100%;
			font-weight: $font-weight-bold;
			@include breakpoint($md) {
				width: auto;
			}
		}
		&__items {
			display: flex;
			flex-wrap: wrap;
		}
		&__button {
			min-width: gutter-size(12);
			height: 100%;
			margin-left: gutter-size(2);
			padding: gutter-size(3);
			span {
				color: $accent;
			}
			&:hover {
				background: rgba($primary-dark, 0.16);

				span {
					color: $primary-dark;
				}
			}
		}
		.active {
			background: rgba($primary-dark, 0.16);

			span {
				color: $primary-dark;
			}
		}
	}
}
