$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-category-selector {
		position: relative;

		@include breakpoint($md) {
			padding: gutter-size(6);
			background-color: $white;
			border: 1px solid $border-color;
		}
		h4 {
			margin-bottom: gutter-size(5);
		}
		&__accordions {
			border-radius: 0;
		}
		&__accordion {
			margin-bottom: gutter-size(2);
			border-top: 1px solid $border-color;
		}
		&__header {
			min-height: 0;
			margin-top: gutter-size(1);
			padding: gutter-size(5) gutter-size(3);
			border-left: 4px solid $white;
			#{$interact} {
				color: $primary-dark;
				border-left: 4px solid $secondary-dark;
				transition: all $easing $timing-fast;
			}
		}
		&__title {
			width: 100%;
			color: $accent;
			font-size: $font-size-root;
		}
		&__content {
			padding: 0;
			ul {
				padding: 0;
				list-style-type: none;
				color: $accent-light;
				li {
					span {
						display: block;
						padding: gutter-size(3) gutter-size(5);
						font-size: $font-size-root;
						font-weight: $font-weight-bold;
						border-left: 4px solid $white;
						&.active,
						#{$interact} {
							color: $primary-dark;
							background: $gray-100;
							border-left: 4px solid $secondary-dark;
							transition: all $easing $timing-fast;
						}
					}
					ul {
						padding-left: gutter-size(6);
					}
				}
			}
		}
		&__clear {
			font-size: $font-size-small;
		}
		&__accordion-icon {
			width: gutter-size(6);
			height: gutter-size(6);
			color: $primary-dark;
			text-align: right;
			&--minus {
				display: none;
			}
		}
		&__filter {
			position: relative;
			height: 0;
			opacity: 0;
			transition: opacity $easing $timing-fastest;
			&--active {
				height: auto;
				margin-top: gutter-size(4);
				padding:gutter-size(4);
				background-color: $white;
				opacity: 1;
				&:before {
					position: absolute;
					top: 0;
					left: gutter-size(-7);
					width: 200%;
					height: 100%;
					content: "";
				}
			}
			&-button {
				width: 100%;
				@include breakpoint($md) {
					display: none !important;
				}
			}
			@include breakpoint($md) {
				height: auto;
				opacity: 1;
				&--active {
					&:before {
						content: none;
					}
				}
			}
		}
		.v-expansion-panel-content__wrap {
			padding: 0;
		}
		.v-expansion-panel-header--active {
			.scl-category-selector__title {
				color: $primary-dark;
			}
			border-left: 4px solid $secondary-dark;

			.scl-category-selector__accordion-icon--plus {
				transform: rotate(180deg);
			}
		}
	}
}
