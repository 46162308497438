$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-shipping-options {
		padding: gutter-size(4) 0;
		&__selection{
			margin: gutter-size(4) 0;
			&-radio {
				font-size: $font-size-large;
				font-weight: $font-weight-bold;
			}
			&-cost {
				font-weight: $font-weight-bold;
				float: right;
			}
		}
	}
}
