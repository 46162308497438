$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-checkout {
		position: relative;
		&__title-row {
			margin-bottom: 80px;
		}
		&__title {
			margin-bottom: 0;
		}
		a {
			color: $primary-dark;
			text-decoration: underline;
			#{$interact} {
				text-decoration: none;
			}
		}
		@include breakpoint($lg) {
			&__col-offset {
				position: relative;
				left: 6%;
			}
		}
	}
	// forms
	.scl-checkout__form {
		&-label {
			@include clearfix;
			display: block;
			margin-bottom: gutter-size(2);
			color: $accent;
			font-weight: $font-weight-semibold;
			font-size: $font-size-root;
			line-height: $line-height-sm;
			&.is-optional {
				padding-right: 70px;
				&:before {
					position: relative;
					right: -70px;
					float: right;
					color: #575f65;
					font-weight: $font-weight-regular;
					font-size: $font-size-xsmall;
					line-height: 20px;
					content: "Optional";
				}
			}
			&.is-disabled {
				opacity: $opacity-half;
			}
		}
		&-title {
			position: relative;
			display: block;
			margin-bottom: gutter-size(6);
			font-weight: $font-weight-bold;
			font-size: 20px;
			span {
				position: relative;
				padding-right: gutter-size(4);
				background-color: $white;
			}
			&:before {
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				height: 2px;
				background-color: $secondary;
				transform: translateY(-50%);
				content: "";
			}
		}
		&-input {
			border-radius: $border-radius-root;
			fieldset {
				border-color: $border-color;
			}
			input {
				color: $accent;
				font-weight: $font-weight-semibold;
				font-size: $font-size-root;
				line-height: $line-height-root;
			}
			.v-input__slot:hover {
				fieldset {
					border-color: $border-hover-color;
				}
			}
			.v-text-field__details {
				padding: 0;
			}
			.v-messages__message {
				color: #575f65;
				font-weight: $font-weight-regular;
				font-size: $font-size-xsmall;
			}
			&.error--text {
				fieldset {
					border: 1px solid $error;
					box-shadow: 0 0 2px 1px rgba($error, 0.2);
				}
				input {
					color: $accent !important;
				}
			}
			.error--text {
				.v-messages__message {
					color: $error;
				}
			}
			&.v-input--is-disabled {
				opacity: $opacity-half;
			}
		}
		&-selection {
			label {
				margin-left: gutter-size(1);
				color: $accent;
				font-size: $font-size-root;
				line-height: $line-height-root;
			}
		}
		&-btn {
			padding: 0 gutter-size(6) 0 gutter-size(8);
			letter-spacing: 0;
			text-transform: unset;
			.v-btn__content {
				justify-content: space-between;
			}
			&.v-btn--outlined {
				border-width: 2px;
			}
			&.v-btn--disabled {
				color: $white !important;
				background-color: $gray-500 !important;
				.v-icon {
					color: $white !important;
				}
			}
		}
		.fa-chevron-down {
			color: $primary-dark;
		}
		@include breakpoint($sm) {
			&-input {
				&.is-narrow {
					width: 50%;
				}
			}
		}
	}
}
