$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-carousel-testimonial {
		padding-bottom: 50px;
		&__carousel {
			overflow: visible;
		}
		.v-window__prev,
		.v-window__next {
			top: auto;
			bottom: -42px;
			margin: 0;
			background: none;
			.v-btn {
				color: $primary-dark;
				.v-icon {
					height: 32px !important;
					font-size: 32px !important;
				}
			}
		}
		.v-carousel__controls {
			right: 50px !important;
			bottom: -50px;
			left: 50px !important;
			width: unset !important;
			&__item {
				margin: 0;
				&:before {
					display: none;
				}
				svg {
					width: 16px !important;
					height: 16px !important;
					color: $gray-100;
					font-size: $font-size-root !important;
					border: 2px solid $primary-dark;
					border-radius: 50%;
					opacity: 1;
				}
			}
			.v-item--active {
				svg {
					color: $primary-dark;
					background-color: $primary-dark;
				}
			}
		}
		&__row {
			height: 100%;
		}
		&__card {
			height: 100%;
			background-color: $white;
		}
		&__title {
			padding: gutter-size(6) gutter-size(6) gutter-size(1);
			color: $primary-dark;
			font-weight: $font-weight-bold;
			font-size: 24px;
			line-height: $line-height-root;
		}
		&__subtitle {
			margin-top: 0;
			padding: 0 gutter-size(6) gutter-size(2);
			color: $accent;
			font-weight: $font-weight-bold;
			font-size: $font-size-root;
			line-height: $line-height-root;
		}
		&__text {
			padding: 0 gutter-size(6);
			color: $accent;
			font-size: $font-size-root;
			line-height: $line-height-root;
		}
		@include breakpoint($sm) {
			.v-responsive__content {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
			&__title {
				padding-right: 45%;
				padding-left: 7%;
			}
			&__subtitle {
				padding-right: 45%;
				padding-left: 7%;
			}
			&__text {
				padding-right: 45%;
				padding-left: 7%;
			}
		}
		@include breakpoint($lg) {
			.v-window__prev,
			.v-window__next {
				.v-btn {
					svg {
						width: 48px !important;
						height: 48px !important;
						font-size: 48px !important;
					}
				}
			}
			&__title {
				padding-left: 15%;
				font-size: 32px;
			}
			&__subtitle {
				padding-left: 15%;
				font-size: $font-size-root;
			}
			&__text {
				padding-left: 15%;
				font-size: 24px;
			}
		}
	}
}
