$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-category-pdf-list {
		&__card {
			height: 100%;
			display: flex;
			justify-content: space-between;
			border: 2px solid $primary;
			border-radius: 0;
			#{$interact} {
				background: $secondary;
				border-color: transparent;
				cursor: pointer;
			}

			.v-image {
				margin-right: 0.5rem;

				@include breakpoint($md) {
					margin-right: 1rem;

				}
			}
			&-content {
				display: flex;
				flex-direction: column;
				width: 100%;
				height: 100%;
				padding: 1rem 1.5rem 0.5rem;
				padding-right: 0.5rem;
				
				.v-card__title {
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					font-size: 18px;
					color: $primary-dark;
					line-height: 1.5;
					padding: 0;
					font-weight: 600;
				}
				.v-card__text {
					padding: 0;
					margin-top: auto;
				}
				
				.v-icon {
					color: $primary-dark;
					height: 40px;
					width: 40px;
					font-size: 1rem;
					padding: 0.5rem;
				}
			}
		}
	}
}
