$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-training-listing {
		position: relative;

		&__sorting {
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
		}
		&__sorting-col {
			padding: gutter-size(2) gutter-size(4);
		}
		@include breakpoint($md) {
			&__search {
				order: 1;
			}
		}
		// print css
		@media print {
			.scl-training-filtering,
			.scl-training-search {
				display: none;
			}
		}
	}
}
