$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-page-size {
		&__title {
			width: 100%;
			margin-right: gutter-size(2);
			font-weight: $font-weight-bold;
			@include breakpoint($sm) {
				width: auto;
			}
		}
		&__items {
			display: flex;
			flex-wrap: wrap;
		}
		&__button {
			min-width: gutter-size(10);
			height: 100%;
			margin: 0 gutter-size(1);
			padding: gutter-size(2);
			> span {
				color: $accent;
				line-height: gutter-size(6);
			}
			&:hover {
				background: $primary-light;
				span {
					color: $primary-dark;
				}
			}
		}
		.active {
			background: $primary-light;
			span {
				color: $primary-dark;
			}
		}
		// print css
		@include print-css-hide;
	}
}
