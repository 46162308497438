$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-list {
		position: relative;

		&__filter-button {
			width: 100%;
			margin-bottom: gutter-size(4);
			@include breakpoint($md) {
				display: none !important;
			}
		}
		&__filter {
			position: relative;
			height: 0;
			opacity: 0;
			transition: opacity $easing $timing-fast;
			&--active {
				height: auto;
				padding: gutter-size(4) 0;
				opacity: 1;
				&:before {
					position: absolute;
					top: 0;
					left: gutter-size(-7);
					width: 200%;
					height: 100%;
					background: $gray-100;
					content: "";
				}
			}
			@include breakpoint($md) {
				height: auto;
				opacity: 1;
				&--active {
					&:before {
						content: none;
					}
				}
			}
		}
		&__sorting {
			position: relative;		}
		&__sorting-col {
			padding: gutter-size(2) gutter-size(4);
			>div {
				gap: 1rem;
			}
		}
		&__clear {
			float: right;
			text-transform: unset;
		}
		&__list-view {
			display: none;
			padding: gutter-size(3);
			background: $primary-light;
			cursor: pointer;
			.v-icon {
				color: $primary-dark;
			}
			&.active, #{$interact} {
				background: $primary-dark;
				.v-icon {
					color: $white;
				}
			}
			@include breakpoint($md) {
				display: inline-flex;
			}
		}
		&__filter-btn {
			background: $secondary;
			border-color: $secondary;
			color: $accent;
			padding: 0 gutter-size(8);
			width: 100%;
			height: 48px;
			.v-icon {
				color: $primary;
			}
			#{$interact} {
				background-color: $secondary-dark;
				border-color: $secondary-dark;
				color: $accent;
				.v-icon {
					color: $primary;
				}
			}
			@include breakpoint($md) {
				width: auto;
			}
		}
		.scl-product-sort {
			margin: 0;
			width: 100%;
			@include breakpoint($md) {
				width: auto;
			}
		}
		// print css
		@media print {
			.scl-product-search,
			.scl-product-list__filter,
			.scl-product-page-size,
			.scl-product-sort {
				display: none;
			}
		}
	}
}
