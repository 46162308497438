$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-mini-nav {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: zindex(level2);
		width: 100%;
		&__list {
			background: transparent;
		}
		&__items {
			position: relative;
			justify-content: flex-end;
			padding: 0;
		}
		&__item {
			margin-bottom: gutter-size(2);
			padding: gutter-size(2.5);
			border-radius: $border-radius-root;
			&--active {
				background: $primary-light;
			}
			#{$interact} {
				background: $primary-light;
			}
		}
		&__tooltip {
			background: $accent;
		}
		&__mobile-nav {
			width: 100%;
			padding: gutter-size(2);
			background: $white;
			box-shadow: $shadow-box;
			&__list {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			&__items {
				margin-right: gutter-size(2);
			}
			&__item {
				display: block;
				padding: gutter-size(3) gutter-size(2);
				border-radius: $border-radius-root;
				&--active {
					background: $primary-light;
				}
				#{$interact} {
					background: $primary-light;
				}
			}
			&__extend {
				flex: 0 1 auto;
				text-align: right;
				&-icon {
					float: right;
					padding: 0 gutter-size(4);
					border-left: 1px solid $border-color;
				}
				&-list {
					padding: gutter-size(2) gutter-size(3);
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;
				}
				&-items {
					padding: gutter-size(2) 0;
					border-bottom: 1px solid $border-color;
					&:last-of-type {
						border: none;
					}
				}
				&-item {
					display: flex;
					align-items: center;
					width: 100%;
					padding: gutter-size(3) gutter-size(2);
					font-weight: $font-weight-bold;
					border-radius: $border-radius-root;
					&--active {
						color: $primary-dark;
						background: $primary-light;
					}
					#{$interact} {
						background: $primary-light;
					}
				}
				&-title {
					margin-left: gutter-size(6);
				}
			}
			&__slider {
				width: 100%;
				margin-bottom: gutter-size(2);
				text-align: center;
				a {
					display: block;
					width: gutter-size(10);
					margin: 0 auto;
					border-bottom: 4px solid $border-color;
					border-radius: $border-radius-root;
				}
			}
		}
		@include breakpoint($md) {
			position: relative;
			&__mobile-nav {
				display: none;
			}
		}
	}
}
