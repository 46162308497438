$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-multi-step-framework {
		&__header {
			margin: 0 auto;
			text-align: center;
		}
		background: $gray-100;
		&__slider {
			box-shadow: none;
			@include breakpoint($sm) {
				margin-bottom: gutter-size(20);
			}
		}
		&__col {
			margin: 0 auto;
		}
		&__stepper-item {
			padding: 0;
			background: transparent;
		}
		&__slide-item {
			position: relative;
			flex: 1 1 auto;
			&:first-of-type {
				.scl-multi-step-framework__progress-circle {
					justify-content: flex-start;
					span {
						text-align: left;
					}
				}
			}
			&:last-of-type {
				flex: 0 0 auto;
				.scl-multi-step-framework__progress-circle {
					justify-content: flex-end;
					span {
						text-align: right;
					}
				}
				.scl-multi-child-step-framework__spacer {
					display: none;
				}
			}
		}
		&__progress-bar {
			position: absolute;
			top: 12px;
			right: -10px;
			width: 100%;
			height: 8px;
			background-color: #abafb2;
			transition: background-color $timing-faster $easing;
			&.is-filled {
				background-color: $primary-dark;
			}
		}
		&__progress-circle {
			position: relative;
			z-index: 1;
			display: flex;
			flex-shrink: 0;
			justify-content: center;
			width: 32px;
			height: 32px;
			background: $white;
			border: 8px solid #abafb2;
			border-radius: 50%;
			transition: border $timing-faster $easing;
			span {
				position: relative;
				bottom: -#{gutter-size(9)};
				display: none;
				flex: 1 1 100%;
				min-width: 150px;
				color: $accent;
				text-align: center;
				@include breakpoint($sm) {
					display: block;
				}
			}
			&.is-filled {
				border: 8px solid $primary-dark;
				span {
					color: $primary-dark;
					font-weight: $font-weight-semibold;
				}
			}
		}
		.v-stepper {
			padding-bottom: 0;
			background: transparent;
			box-shadow: none;
		}
		.scl-alert--flat {
			.v-alert__icon {
				display: none;
				margin-top: gutter-size(2);
				@include breakpoint($sm) {
					display: block;
				}
			}
		}
	}
}
