$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-store-category-list {
		padding: gutter-size(6);
		background: $white;
		border: 1px solid $border-color;

		&__item {
			border-top: 1px solid $border-color;
			a {
				display: block;
				margin: gutter-size(1) 0;
				padding: gutter-size(4);
				border-left: 4px solid $white;
				#{$interact} {
					color: $primary-dark;
					border-left: 4px solid $secondary-dark;
					transition: all $easing $timing-fast;
				}
			}
			&:last-child {
				border-bottom: 1px solid $border-color;
			}
			&--active {
				a {
					border-left: 4px solid $secondary-dark;
				}
			}
			&-child {
				margin-left: gutter-size(4);
			}
		}
	}
}
