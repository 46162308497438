/*! animate */

.card-accent {
	border-radius: $border-radius-root;
	&__secondary {
		background-color: $secondary;
	}
	&__secondary-dark {
		background-color: $secondary-dark;
	}
}
.card-accent--bottom {
	padding-bottom: gutter-size(1.5);
	.v-card {
		top: 0;
		right: 0;
		transition: all $easing $timing-fast;
	}
	#{$interact} {
		.v-card {
			right: gutter-size(-1.5);
			height: 100% !important;
		}
	}
}
.card-accent--left {
	.v-card {
		top: 0;
		left: gutter-size(1.5);
		width: calc(100% - #{gutter-size(1.5)}) !important;
		transition: all $easing $timing-fast;
	}
	#{$interact} {
		.v-card {
			top: gutter-size(-1.5);
			height: 100% !important;
		}
	}
}
