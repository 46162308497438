$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-footer-contact {
		padding-bottom: gutter-size(7.5);
		background: $gray-100;
		&__title {
			@include h4;
			margin-bottom: gutter-size(3);
			color: $accent;
			font-weight: $font-weight-bold;
		}
		&__title-icon {
			@include h4;
			display: none;
			margin-right: gutter-size(5);
			color: $accent;
		}
		&__number {
			margin-bottom: gutter-size(1);
			color: $accent;
			font-weight: $font-weight-bold;
		}
		&__link {
			display: block;
			margin-right: gutter-size(6);
			font-size: $font-size-small;
			line-height: $line-height-xl;
			text-decoration: underline;
		}
		@include breakpoint($md) {
			padding: gutter-size(10) 0 gutter-size(5);
			&__title {
				margin-bottom: gutter-size(6);
			}
			&__title-icon {
				display: inline-block;
			}
			&__content {
				margin-left: gutter-size(12.5);
			}
			&__number {
				margin-bottom: gutter-size(4);
			}
			&__link {
				display: inline-block;
			}
		}
	}
}
