$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-sort {
		margin-left: (gutter-size(4));
		&__content {
			display: flex;
			flex-wrap: wrap;
		}
		&__title {
			width: 100%;
			padding-right: gutter-size(4);
			font-weight: $font-weight-bold;
			@include breakpoint($md) {
				width: auto;
			}
		}
		.fa-caret-down {
			color: $primary-dark;
		}
		.v-select {
			.dropdown {
				.dropdown-menu {
					min-width: initial;
				}
			}
		}
		.v-input__slot {
			align-items: center;
			border-radius: 0;
			color: $accent;
			font-weight: $font-weight-bold;
			.v-input__append-inner {
				margin-top: gutter-size(1);
			}
			.v-icon {
				color: $primary-dark;
			}
		}
		.v-text-field--outlined fieldset {
			border-color: $border-color;
		}
		.v-input--is-focused fieldset {
			border: 1px solid $border-color;
		}
		.v-label {
			position: relative;
			font-weight: $font-weight-regular;
			font-size: $font-size-root;
		}
	}
}
