$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-carousel {
		padding: gutter-size(10) 0;
		&__heading {
			color: $accent;
			font-size: $font-size-H4-max;
		}
		&__carousel {
			overflow: visible;
		}

		&__item {
			.v-carousel__item {
				overflow: visible;
			}
		}
		.v-carousel__controls {
			bottom: gutter-size(-12.5);
			justify-content: center;
			&__item {
				margin: 0;
				&:before {
					display: none;
				}
				svg {
					width: gutter-size(4) !important;
					height: gutter-size(4) !important;
					color: $gray-100;
					font-size: $font-size-root !important;
					border: 2px solid $primary-dark;
					border-radius: 50%;
					opacity: 1;
				}
			}
			.v-item--active {
				svg {
					color: $primary-dark;
					background-color: $primary-dark;
				}
			}
		}
		&__row {
			height: 100%;
		}
		.v-lazy {
			height: 100%;
		}
		&__card {
			height: 100%;
			background-color: $white;
			&:before {
				content: none;
			}
		}
		&__title {
			word-break: break-word;
			h3 {
				margin-bottom: 0;
				font-size: $font-size-xlarge;
			}
		}
		&__text {
			flex: 1 0 auto;
		}
		&__price {
			display: flex;
			margin: gutter-size(3) gutter-size(3);
		}
		&__price-item {
			padding-top: gutter-size(5);
			&:first-child {
				padding-right: gutter-size(5);
			}
			span {
				font-weight: $font-weight-semibold;
				font-size: $font-size-xlarge;
			}
			small {
				display: block;
				font-size: $font-size-xsmall;
			}
			&--member {
				span {
					text-decoration: line-through;
				}
			}
		}
		&__stock {
			position: absolute;
			top: gutter-size(2.5);
			right: gutter-size(-2);
			z-index: zindex(level2);
			padding: gutter-size(6);
			color: $white;
			font-weight: $font-weight-regular;
			text-transform: initial;
			background: $error;
		}
		@include breakpoint($sm) {
			padding: gutter-size(20) 0;
			&__heading {
				font-size: $font-size-H2-min;
			}
			&__card {
				height: 100%;
				position: relative;
    			padding-bottom: gutter-size(10);
				&:before {
					content: none;
				}
			}
			&__price {
				margin: gutter-size(3) gutter-size(2);
				bottom: 0;
				position: absolute;
			}
		}
	}
}
