$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-store-detail {
		h3 {
			color: $primary-dark;
		}
		&__contact {
			div {
				display: flex;
				b {
					flex: 0 1 25%;
				}
			}
		}
		&__opening {
			div {
				display: flex;
				justify-content: space-between;
				span {
					flex: 0 1 50%;
				}
			}
		}
	}
}
