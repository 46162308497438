$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-related-products {
		position: relative;
		padding: 0 gutter-size(4) gutter-size(10);
		overflow: hidden;
		&__heading {
			color: $accent;
			font-size: $font-size-H4-max;
		}
		&__carousel {
			overflow: visible;
			.v-window__container {
				overflow: visible;
			}
		}

		&__item {
			.v-carousel__item {
				overflow: visible;
			}
		}
		.v-carousel__controls {
			bottom: gutter-size(-12.5);
			justify-content: center;
			&__item {
				margin: 0;
				&:before {
					display: none;
				}
				svg {
					width: gutter-size(4) !important;
					height: gutter-size(4) !important;
					color: $primary-light;
					background-color: $primary-light;
					font-size: $font-size-root !important;
					border-radius: 50%;
					opacity: 1;
				}
			}
			.v-item--active {
				svg {
					color: $primary-dark;
					background-color: $primary-dark;
				}
			}
		}
		.v-window__prev {
			margin: 0;
			padding: gutter-size(1.5);
			margin-left: gutter-size(-4);
			background: $primary-dark;
			border-radius: 0;
			.v-icon {
				width: 24px !important;
				height: 24px !important;
				font-size: 24px !important;
			}
			.v-btn {
				&:before {
					content: none;
				}
				.v-ripple__container {
					opacity: 0;
				}
			}
		}
		.v-window__next {
			margin: 0;
			padding: gutter-size(1.5);
			margin-right: gutter-size(-4);
			background: $primary-dark;
			border-radius: 0;
			.v-icon {
				width: 24px !important;
				height: 24px !important;
				font-size: 24px !important;
			}
			.v-btn {
				&:before {
					content: none;
				}
				.v-ripple__container {
					opacity: 0;
				}
			}
		}
		&__row {
			height: 100%;
		}
		&__card {
			display: flex;
			flex-direction: column;
			height: 100%;
			background-color: $white;
			border: 1px solid $border-color;
			border-radius: 0;
			#{$interact} {
				.scl-related-products__title {
					color: $secondary-dark;
					transition: all $easing $timing-faster;
				}
				.v-icon {
					color: $secondary-dark;
					transition: all $easing $timing-faster;
				}
			}
		}
		&__title {
			flex-wrap: nowrap;
			height: 100%;
			margin-bottom: 0;
			padding: gutter-size(6);
			font-size: $font-size-large;
			font-weight: $font-weight-black;
			line-height: $line-height-root;
			background-color: $gray-200;
			border-top: 1px solid $border-color;
			word-break: break-word;
			.v-icon {
				color: $primary-dark;
			}
		}
		@include breakpoint($sm) {
			&__heading {
				font-size: $font-size-H2-min;
			}
		}
	@include print-css-hide;
	}
}
