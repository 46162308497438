$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-footer {
		padding: gutter-size(3) 0 gutter-size(6);
		background: $gray-100;
		border-top: 1px solid $border-color;
		font-size: $font-size-small;
		&__list {
			background: $gray-100;

			a {
				min-height: 0;
				padding: 0;
				text-decoration: underline;
				&:before {
					content: none;
				}
			}
		}
		&__header {
			height: 100%;
			padding: gutter-size(6) 0 gutter-size(4.5);
			color: $black;
			font-weight: $font-weight-bold;
			font-size: $font-size-root;
		}
		&__expansion-panel {
			background: $gray-100;
			border: none;
			&:before {
				box-shadow: none;
			}
			.scl-footer__list {
				border-top: 1px solid $gray-300;
			}
		}
		&__expansion-panel-header {
			padding: 0;
		}
		&__title {
			width: 100%;
			color: $primary-dark;
		}
		&__icon {
			width: gutter-size(4);
			height: gutter-size(4);
			color: $primary-dark;
			&--minus {
				display: none;
			}
		}
		&__lower-logo {
			display: flex;
			align-items: center;
			span {
				padding-left: gutter-size(3);
			}
			img {
				max-width: 213px;
				max-height: gutter-size(9.5);
				@include breakpoint($sm) {
					max-height: gutter-size(14);
				}
			}
			
		}
		&__lower-links {
			a {
				font-weight: $font-weight-semibold;
			}
			@include breakpoint($md) {
				text-align: right;
			}
		}
		.v-expansion-panel-content__wrap {
			padding: 0;
		}
		.v-expansion-panel-header--active {
			padding-bottom: 0;
			color: $primary-dark;
			transition-delay: 0.3s;
			.scl-footer__icon--minus {
				display: block;
			}
			.scl-footer__icon--plus {
				display: none;
			}
		}
		a {
			#{$interact} {
				text-decoration: underline;
			}
		}
		&__copyright {
			color: $gray-600;
		}
		@include breakpoint($sm) {
			padding: gutter-size(9) 0;
		}
		@include breakpoint($md) {
			.container {				
				max-width: 1608px;
			}
		}
		// print css
		@include print-css-hide;
	}
}
