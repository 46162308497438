$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product {
		position: relative;
		&__price-container {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
		&__price {
			font-size: $font-size-xsmall;
		}
		&__title {
			@include h2;
		}
		&__strike-through {
			text-decoration: line-through;
		}
		.scl-alert--contextual {
			margin: gutter-size(4) 0 gutter-size(8);
			border: 1px solid #dddfe0;
			border-bottom: 0;
			box-shadow: $shadow-box;
		}
		.scl-alert__title {
			@include h3;
			margin-bottom: 0;
			line-height: $line-height-sm;
		}
		.scl-alert__copy {
			margin: gutter-size(4) 0 0 0;
		}
		&__desc {
			margin-bottom: gutter-size(4);
		}
		@include breakpoint($sm) {
			.scl-alert--contextual {
				.v-alert__content {
					display: flex;
					align-items: flex-end;
				}
			}
			.scl-alert__copy {
				margin: 0 0 0 gutter-size(4);
			}
		}
	}
}
