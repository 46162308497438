$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-pagination {
		&__list {
			display: flex;
			margin: 0;
			li {
				button {
					min-width: gutter-size(12);
					height: 100%;
					margin: 0 gutter-size(0.5);
					padding: gutter-size(2.5);
					color: $primary-dark;
					background: transparent;
					border-radius: 0;
					outline: none;
					box-shadow: none;
					svg {
						color: $primary-dark;
						font-size: $font-size-root;
					}
				}
				&:first-child {
					width: 100%;
					.v-pagination__navigation {
						width: auto;
						&:after {
							content: "Previous";
						}
					}
					.v-icon {
						margin-right: gutter-size(4);
					}
				}
				&:last-child {
					width: 100%;
					justify-content: flex-end;
					.v-pagination__navigation {
						width: auto;
						&:before {
							content: "Next";
						}
						.v-icon {
							margin-left: gutter-size(4);
						}
					}
				}
			}
			.v-pagination__item {
				#{$interact} {
					color: $white;
					background: $primary-dark;
				}
				&--active {
					color: $white;
					background: $primary-dark !important;
				}
			}
		}
		// print css
		@include print-css-hide;
	}
}
