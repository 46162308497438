$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-facets {
		position: relative;
		&__dialog {
			position: absolute;
			max-height: 100%;
			height: 100%;
			top: 0;
			bottom: 0;
			right: 0;
			margin: 0;
			background: $gray-100;
			overflow: auto;
			border-radius: 0;
			@include breakpoint($sm) {
				max-width: 448px;
	
				}
		}
		&__accordion {
			background: $white;
			margin-bottom: 0;
			padding: 0 gutter-size(5);
			&--show {
				.scl-facets__check--hidden {
					max-height: 100%;
					margin-top: gutter-size(4);
					padding-top: gutter-size(1);
				}
				.scl-facets__show-more {
					&:before {
						content: "Less";
					}
				}
			}
			&:last-child {
				.scl-facets__content {
					border-bottom: none;
				}
			}
		}
		&__heading {
			position: relative;
			padding: gutter-size(5);
			margin: 0;
			color: $primary-dark;
			&-icon {
				color: $primary-dark;
				margin-right: gutter-size(5);
			}
		}
		&__header {
			min-height: 0;
			padding: gutter-size(5) 0;
			
		}
		&__title {
			width: 100%;
		}
		&__content {
			padding: 0 0 gutter-size(2);
			.v-expansion-panel-content__wrap {
				padding: 0 0 gutter-size(2);
			}
			border-bottom: 1px solid $border-color;
		}
		&__check {
			@include clearfix;
			width: 100%;
			max-height: 100%;
			transition: max-height 0.4s ease-in, margin 0.4s ease-in;

			&:first-of-type {
				margin-top: 0;
			}
			label {
				color: $accent;
				font-size: $font-size-root;
			}
			svg {
				color: $gray-500;
			}
			&--hidden {
				max-height: 0;
				margin: 0;
				padding: 0;
				overflow: hidden;
				border: none;
			}
		}

		&__clear {
			font-size: $font-size-small;
		}
		&__accordion-icon {
			width: gutter-size(4);
			height: gutter-size(4);
			color: $primary-dark;
			text-align: right;
			&--minus {
				display: none;
			}
		}
		&__filter {
			position: relative;

			transition: opacity $easing $timing-fast;
			margin-bottom: 80px;
			&--active {
				height: auto;
				padding: gutter-size(4) 0;
				opacity: 1;
				&:before {
					position: absolute;
					top: 0;
					left: gutter-size(-7);
					width: 200%;
					height: 100%;
					background: $gray-100;
					content: "";
				}
			}
			&-button {
				width: 100%;
				margin: gutter-size(4) 0;
				@include breakpoint($md) {
					display: none !important;
				}
			}
			height: auto;
			opacity: 1;
			&--active {
				&:before {
					content: none;
				}
			}
		}
		&__header.v-expansion-panel-header--active {
			.scl-facets__accordion-icon--minus {
				display: block;
			}
			.scl-facets__accordion-icon--plus {
				display: none;
			}
		}
		&__show-more {
			display: block;
			margin-top: gutter-size(3);
			&:before {
				content: "More";
			}
		}
		&__footer {
			position: fixed;
			bottom: 0;
			z-index: 1;
			width: 100%;

			padding: gutter-size(5);
			background: $gray-100;
			display: flex;
			justify-content: space-between;
			align-items: center;
			@include breakpoint($sm) {
			max-width: 448px;

			}
		}
	}
}
