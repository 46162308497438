$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-article-sorting {
		position: relative;
		float: left;
		@include breakpoint($md) {
			float: right;
		}
		&__content {
			display: flex;
			flex-wrap: wrap;
		}
		&__title {
			width: 100%;
			padding-right: gutter-size(4);
			font-weight: $font-weight-bold;
			@include breakpoint($md) {
				width: auto;
			}
		}
		&__select {
			.v-list-item__title {
				font-size: $font-size-root;
				color: $accent;
			}
		}
		.fa-caret-down {
			color: $primary-dark;
		}
		.v-select {
			.dropdown {
				.dropdown-menu {
					min-width: initial;
				}
			}
		}
		.v-input__slot {
			align-items: center;
			min-width: gutter-size(62);
			background-color: $gray-100;
			border: 1px solid $border-color;
			box-shadow: none;
		}
		.v-label, .v-select__selection {
			position: relative;
			margin: 0;
			font-weight: $font-weight-regular;
			font-size: $font-size-root;
			line-height: 1;
			color: $accent;
		}
		input {
			width: 0;
		}
		
	}
}
