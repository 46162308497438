$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-cart-notification {
		.v-snack {
			right: 0;
			bottom: 0;
			left: 0;
		}
		.v-snack__wrapper {
			max-width: 100%;
			background: none;
			box-shadow: $shadow-box;
		}
		.v-snack__content {
			padding: 0;
		}
		.scl-alert__alert {
			width: 100vw;
			margin-bottom: 0;
		}
		.v-alert__content {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: space-between;
		}
		.v-btn--icon {
			height: auto;
		}
		.scl-alert--success {
			.v-btn {
				margin-top: -8px;
				.v-icon {
					color: $primary-dark !important;
					font-size: 32px;
					width: 32px;
					height: 32px;
				}
			}

			.scl-alert__title,
			a {
				color: $primary-dark;
				#{$interact} {
					color: $primary-dark;
				}
			}
			.v-alert__border--bottom {
				background-color: $primary-dark !important;
				border-color: $primary-dark !important;
			}
		}
		.scl-alert--error {
			.v-icon {
				color: $error !important;
			}
		}
		@include breakpoint($sm) {
			.scl-alert__alert {
				width: auto !important;
			}
		}
	}
}
