/*! mixins */

// clearfix
@mixin clearfix() {
	&:after {
		display: block;
		clear: both;
		content: "";
	}
}

// remove units
@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}

// ---------------------------------------------------------------------
// typography
// ---------------------------------------------------------------------

// fluid type
// https://css-tricks.com/snippets/css/fluid-typography/
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);
	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
			font-size: $min-font-size;
		}
	}
}
// h1
@mixin h1($min: $font-size-H1-min, $max: $font-size-H1-max) {
	@include fluid-type($min-type-width, $max-type-width, $min, $max);
	@content;
}
// h2
@mixin h2($min: $font-size-H2-min, $max: $font-size-H2-max) {
	@include fluid-type($min-type-width, $max-type-width, $min, $max);
	@content;
}
// h3
@mixin h3($min: $font-size-H3-min, $max: $font-size-H3-max) {
	@include fluid-type($min-type-width, $max-type-width, $min, $max);
	@content;
}
// h4
@mixin h4($min: $font-size-H4-min, $max: $font-size-H4-max) {
	@include fluid-type($min-type-width, $max-type-width, $min, $max);
	@content;
}
// h5
@mixin h5($min: $font-size-H5-min, $max: $font-size-H5-max) {
	@include fluid-type($min-type-width, $max-type-width, $min, $max);
	@content;
}
// h6
@mixin h6($min: $font-size-H6-min, $max: $font-size-H6-max) {
	@include fluid-type($min-type-width, $max-type-width, $min, $max);
	@content;
}
// tick svg
@mixin table-tick($color) {
	background-image: url("data:image/svg+xml,%3Csvg width='33' height='33' viewBox='0 0 33 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='4.58374' height='14.0377' transform='matrix(0.709001 -0.705207 0.709001 0.705207 0.0263672 22.6274)' fill='#{encodecolor($color)}' /%3E%3Crect width='4.67924' height='32.0862' transform='matrix(-0.709001 -0.705207 0.709001 -0.705207 9.979 32.5269)' fill='#{encodecolor($color)}'/%3E%3C/svg%3E%0A");
}
// blockquote svg
@mixin blockquote($color) {
	background: url("data:image/svg+xml,%3Csvg width='120' height='91' viewBox='0 0 120 91' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.4' d='M42.52 0.439991C42.9467 0.22666 43.8 0.119995 45.08 0.119995C46.36 0.119995 48.92 0.759993 52.76 2.03999C56.6 3.31999 59.0533 4.38665 60.12 5.23999C52.0133 14.4133 43.2667 25.4 33.88 38.2C39.8533 39.6933 44.8667 42.7867 48.92 47.48C52.9733 52.1733 55 57.9333 55 64.76C55 71.3733 52.44 77.24 47.32 82.36C42.4133 87.48 36.6533 90.04 30.04 90.04C29.6133 90.04 29.2933 90.04 29.08 90.04C20.9733 90.04 14.1467 87.6933 8.6 83C3.26667 78.3067 0.6 71.2667 0.6 61.88C0.813334 52.4933 5.61333 41.72 15 29.56C24.6 17.4 33.7733 7.69332 42.52 0.439991ZM102.68 0.439991C103.107 0.22666 103.853 0.119995 104.92 0.119995C106.2 0.119995 108.867 0.866659 112.92 2.35999C116.973 3.63998 119.213 4.59998 119.64 5.23999C108.973 17.6133 100.44 28.6 94.04 38.2C100.013 39.6933 104.92 42.7867 108.76 47.48C112.813 51.96 114.84 57.72 114.84 64.76C114.84 71.5867 112.387 77.56 107.48 82.68C102.573 87.5867 96.7067 90.04 89.88 90.04C89.4533 90.04 89.1333 90.04 88.92 90.04C81.0267 90.04 74.3067 87.6933 68.76 83C63.4267 78.3067 60.76 72.12 60.76 64.44C60.76 56.5467 63.7467 47.8 69.72 38.2C78.8933 23.6933 89.88 11.1067 102.68 0.439991Z' fill='#{encodecolor($color)}'/%3E%3C/svg%3E");
}
// encode hex for svg
@function encodecolor($string) {
	@if type-of($string) == "color" {
		$hex: str-slice(ie-hex-str($string), 4);
		$string: unquote("#{$hex}");
	}
	$string: "%23" + $string;
	@return $string;
}

// ---------------------------------------------------------------------
// print css
// ---------------------------------------------------------------------

@mixin print-css-hide {
	@media print {
		display: none;
	}
}
