.container {
	position: relative;
}
.banner-override {
	.scl-carousel-media {
		&__carousel {
			height: 100% !important;
		}
		&__content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: gutter-size(8) gutter-size(6) !important;
			&--right {
				align-items: flex-end;
			}
			@include breakpoint($sm) {
				padding: gutter-size(10) gutter-size(15) !important;
			}
		}
		.v-window__container {
			height: 100% !important;
		}
		.v-carousel__item {
			height: 100% !important;
		}
	}
	.scl-card-video {
		padding-bottom: gutter-size(6);
		&:last-of-type {
			padding: 0;
		}
		.v-responsive__sizer {
			padding-bottom: 45% !important;
		}
	}
}

.page-watermark {
	@include breakpoint($md) {
		padding-top: 0;
		&:before {
			position: absolute;
			right: 0;
			width: 700px;
			height: 800px;
			margin-top: -150px;
			background-image: url("#{$image-path}/watermark.png");
			background-repeat: no-repeat;
			background-position: right;
			background-size: contain;
			content: "";
		}
	}
}

.flex-column {
	.col {
		display: flex;
		flex-direction: column;
	}
}
.content-wrapper {
	padding: gutter-size(6);
	background-color: $white;
	border: 1px solid $border-color;
}