$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-store-list {
		.v-lazy {
			height: 100%;
			background: $secondary-dark;
		}
		&__card {
			height: 100%;
			background: $gray-200;
			border-radius: 0;
			.v-image {
				background: $white;
			}
			&-content {
				padding: gutter-size(6);
				.v-card__title {
					padding: 0;
				}
			}
			&-title {
				margin-bottom: gutter-size(3);
				font-size: $font-size-xlarge;
				word-break: break-word;
				color: $primary-dark;
				font-weight: $font-weight-black;
			}
			&-text {
				flex: 1 0 auto;
				padding: 0;
				font-size: $font-size-root;
				line-height: $line-height-root;
				color: $gray-600;
			}
			#{$interact} {
				transition: all $easing $timing-fast;
				height: calc(100% - 8px);
				.scl-store-list__card-title {
					transition: all $easing $timing-fast;
					color: $secondary-dark;
				}
			}
		}
	}
}
