$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-search-custom {
		&__card {
			padding: gutter-size(4) gutter-size(2);
			@include breakpoint($md) {
				padding: gutter-size(6) gutter-size(10);
			}
		}
		&__title {
			h5 {
				margin-bottom: gutter-size(4);
			}
		}
		&__search {
			position: relative;
			&-input {
				.v-input__slot {
					padding: gutter-size(2.5);
					background: $gray-100;
					border: 1px solid $border-color;
					border-left: 6px solid $secondary;
					box-shadow: inset 0px 4px 12px rgba(45, 55, 62, 0.08);
					@include breakpoint($md) {
						font-size: $font-size-H4-max;
					}
				}
				.v-input__control {
					@include breakpoint($md) {
						min-height: gutter-size(17);
					}
				}
				.v-text-field__details {
					display: none;
				}
				.v-icon {
					margin-right: gutter-size(4);
					color: $primary-dark;
				}
				input::placeholder {
					color: $accent-lighter;
				}
			}
			&-btn {
				position: absolute;
				top: gutter-size(1.5);
				right: gutter-size(1.5);
			}
		}
		&__image {
			margin-right: gutter-size(3);
		}
	}
}
