$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-gallery {
		position: relative;

		&__container {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		&__small {
			display: flex;
			justify-content: flex-start;
			width: 100%;
		}
		&__large {
			width: 100%;
		}
		&__image {
			height: 100%;
			border: 1px solid $border-color;
			border-radius: $border-radius-root;
			opacity: 1;
			transition: all $timing-fastest $easing;
			.v-image__image {
				background-size: contain;
			}
		}
		&__image--small {
			width: 100%;
			max-width: calc(20% - #{gutter-size(2)});
			height: auto;
			margin-right: gutter-size(2);
			&.is-active {
				border: 1px solid $secondary-dark;
			}
			&:last-of-type {
				margin-right: 0;
			}
			#{$interact} {
				border: 1px solid $secondary-dark;
				cursor: pointer;
			}
		}
		&__image--large {
			width: 100%;
			height: auto;
			margin-bottom: gutter-size(2);
			box-shadow: $shadow-box;
			#{$interact} {
				cursor: pointer;
				opacity: $opacity-lots;
			}
		}
		&__image--dialog {
			border: 0;
		}
		&__dialog {
			max-width: 80%;
		}
		&__dialog-container {
			height: auto;
			max-height: 80vh;
			padding-top: gutter-size(6);
		}
		&__dialog-close {
			position: absolute;
			right: gutter-size(2);
			-webkit-appearance: unset;
		}
		@include breakpoint($sm) {
			&__image--small {
				max-width: calc(20% - #{gutter-size(6)});
				margin-right: gutter-size(6);
			}
			&__image--large {
				margin-bottom: gutter-size(6);
			}
		}
		@include breakpoint($md) {
			max-width: 664px;
		}
	}
}
