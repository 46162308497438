$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-cart-summary {
		position: relative;

		&__proceed {
			justify-content: space-between;
			width: 100%;
			margin-top: gutter-size(2);
			padding-left: gutter-size(8);
			color: $white;
			text-transform: unset;
			.v-icon {
				font-weight: $font-weight-regular;
			}
		}
		@include breakpoint($sm) {
			&__items-container {
				@include clearfix;
			}
		}
		@include breakpoint($lg) {
			.scl-cart-order-summary {
				margin-top: 88px;
			}
		}
		@include breakpoint($xl) {
			&__items-container {
				padding-right: 112px;
			}
		}
	}
}
