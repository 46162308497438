$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-map {
		display: flex;
		flex-direction: column;
		height: 100%;
		background-color: #e5e3df;
		border: 1px solid $gray-300;
		border-radius: $border-radius-root;
		&__map-container {
			position: relative;
			height: 100%;
		}
		&__spinner {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: zindex(level1);
			transform: translateX(-50%) translateY(-50%);
		}
		&__map {
			width: 100%;
			height: 100%;
			min-height: 300px;
		}
		&__info {
			background-color: $white;
			p {
				margin: 0;
				padding: 0 gutter-size(6);
				&:first-of-type {
					padding-top: gutter-size(6);
				}
				&:last-of-type {
					padding-bottom: gutter-size(6);
				}
			}
		}
	}
}
