$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-category-carousel-list {
		position: relative;

		&__row {
			padding: gutter-size(4) 0 gutter-size(12);
		}
		&__divider {
			border-top: 1px solid $border-color;
			@include breakpoint($sm) {
				border: none;
			}
		}
		&__subject-card {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 100%;
			@include breakpoint($sm) {
				padding: gutter-size(4);
				border: 1px solid $border-color;
				border-radius: $border-radius-root;
			}
			@include breakpoint($lg) {
				padding: gutter-size(6);
			}
		}
		&__subject-title {
			margin-bottom: gutter-size(4);
			word-break: break-word;
		}
		&__subject-text {
			margin-bottom: 0;
			font-size: $font-size-root;
			@include breakpoint($sm) {
				margin-bottom: gutter-size(6);
			}
			@include breakpoint($md) {
				font-size: $font-size-H4-max;
			}
		}
		&__subject-button {
			justify-content: space-between !important;
		}
	}
}
