$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-category-accordion {
		position: relative;
		border: 1px solid $border-color;
		border-radius: $border-radius-root;
		&__title {
			margin: 0;
			padding: gutter-size(7.5) gutter-size(6) gutter-size(6);
			border-bottom: 1px solid $border-color;
		}
		&__header {
			min-height: gutter-size(14);
			padding: gutter-size(4);
			font-weight: $font-weight-semibold;
			border-radius: $border-radius-root;
			transition: $easing $timing-fast;
			&:before {
				content: none;
			}
			.v-icon {
				color: $primary-dark;
			}
			#{$interact} {
				color: $primary-dark;
				background: rgba($primary-dark, 0.15);
			}
		}
		&__accordions {
			padding: gutter-size(2);
		}
		&__content {
			padding: gutter-size(2) 0;
			.v-expansion-panel-content__wrap {
				padding-bottom: 0;
			}
		}
		&__link {
			display: block;
			width: 100%;
			padding: gutter-size(2.5) 0;
			line-height: $line-height-root;
			#{$interact} {
				color: $primary-dark;
			}
		}
		.v-expansion-panel--active {
			.scl-category-accordion__header {
				color: $primary-dark;
			}
		}
	}
}
