$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-variant-table {
		&__table {
			border: none;
			box-shadow: none;
			thead {
				background: $primary-dark;
				th {
					color: $white;
					text-align: center !important;
					font-weight: $font-weight-bold;
				}
			}
			tbody {
				tr:nth-child(even) {
					background: $gray-100;
				}
				tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
					background: $secondary-dark;
				}
				td {
					text-align: center;
					border-bottom: none;
				}
			}
		}
	}
}
