$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-tertiary-nav {
		position: relative;
		background-color: $white;
		border: 1px solid $border-color;
		border-radius: 0;
		box-shadow: $shadow-box;
		&__title {
			margin: 0;
			padding: gutter-size(10) gutter-size(6) gutter-size(6);
			border-bottom: 1px solid $border-color;
		}
		&__accordions {
			padding: gutter-size(2);
			border-radius: 0;
		}
		&__header {
			height: 56px;
			padding: 0 gutter-size(4);
			font-weight: $font-weight-semibold;
			transition: all $timing-faster $easing;
			.v-expansion-panel-header__icon svg {
				color: $primary-dark;
			}
			#{$interact} {
				color: $primary-dark;
				background-color: $primary-light;
			}
		}
		.v-expansion-panel-header--active {
			min-height: unset;
			color: $primary-dark;
			background-color: $primary-light;
			.v-expansion-panel-header__icon {
				transform: rotate(90deg);
			}
		}
		&__link {
			display: block;
			margin: gutter-size(3) 0;
			color: $accent;
			transition: all $timing-faster $easing;
			#{$interact} {
				color: $primary-dark;
			}
		}
	}
}
