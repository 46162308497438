$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-header {
		position: relative;
		background: $primary-dark;
		color: $white;
		box-shadow: none;
		@include breakpoint($md) {
			z-index: zindex(level3);
			box-shadow: $shadow-box;
			.container {
				max-width: 1608px;
			}
		}
		&__logo {
			img {
				max-height: gutter-size(10);
				@include breakpoint($md) {
					max-width: 100%;
					max-height: 56px;
				}
			}
		}
		&__row {
			@include breakpoint($md) {
				align-items: center;
			}
		}
		&__secondary {
			position: relative;
			height: auto !important;
			color: $white;
			background: $primary-dark;
			contain: initial;
			box-shadow: $shadow-box;
			&__no-shadow {
				box-shadow: none;
			}
			&__link {
				padding: 0 0 0 12px;
				color: $white;
				font-size: $font-size-small;
				#{$interact} {
					color: $secondary;
				}
			}
			.v-toolbar__content {
				align-items: baseline;
				height: auto !important;
				padding: 0;
			}
			&[aria-expanded="true"] {
				box-shadow: none;

				.scl-header__primary-search {
					position: relative;
					background: $gray-100;
					border: 1px solid $border-color;
					border-bottom: none;
					box-shadow: 0 3px 0 $gray-100;
				}
			}
			&--active {
				box-shadow: none;
				@include breakpoint($md) {
					box-shadow: $shadow-box;
				}
			}
		}
		&__secondary-mobile {
			padding: gutter-size(4.5) gutter-size(3);
			box-shadow: 0 gutter-size(1) gutter-size(1) rgba($accent, 0.1);
		}
		&__secondary-link {
			text-decoration: underline;
			@include breakpoint($md) {
				text-decoration: none;
			}
		}
		&__left-side {
			display: flex;
			align-items: center;
			padding: gutter-size(1) 0 0;
			@include breakpoint($md) {
				padding: gutter-size(4) 0;
			}
			.title {
				line-height: 100%;
			}
		}
		&__right-side {
			display: flex;
			flex: 1 0 auto;
			align-items: flex-end;
			height: 100%;
			@include breakpoint($md) {
				align-items: center;
			}
		}
		&__secondary-nav-items {
			flex: 1 0 auto;
			justify-content: end;
			margin-bottom: gutter-size(1);
			.btn {
				font-weight: $font-weight-regular;
			}
		}
		&__secondary-placeholder {
			display: flex;
			flex: 1 0 auto;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: start;
			margin-left: gutter-size(4);
			padding-top: gutter-size(4);
			border-top: 1px solid $border-color;
			@include breakpoint($md) {
				justify-content: end;
				padding: 0;
				border: none;
			}
			&--mobile {
				.scl-header-contact__number a {
					color: $primary-dark;
				}
			}
		}
		&__cart {
			border-right: 1px solid $border-color;
			border-left: 1px solid $border-color;
		}
		&__nav-buttons {
			display: flex;
		}

		&__secondary-icon {
			margin: 0 gutter-size(3);
			color: $secondary;
			font-size: $font-size-H3-max;
		}
		&__secondary-icons {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}
		&__border-right {
			position: relative;
			display: flex;
			align-items: center;
			&:after {
				position: absolute;
				top: 10%;
				right: 0;
				width: 1px;
				height: 80%;
				background: $gray-300;
				content: "";
			}
		}
		&__separator {
			@include breakpoint($md) {
				width: 100%;
				height: 1px;
				margin: gutter-size(3) gutter-size(3) 0;
				background: $gray-100;
			}
		}
		&__primary-nav-items {
			flex-wrap: wrap;
			.v-btn:not(.v-btn--round) {
				padding: gutter-size(3) gutter-size(1.5);
			}
		}
		&__mega-menu {
			right: 0 !important;
			left: 0 !important;
			width: 100% !important;
			min-width: 100% !important;
			padding: gutter-size(6) 0;
			background-color: $white;
			background-image: url("#{$image-path}/leaf.png");
			background-repeat: no-repeat;
			background-position: left;
			background-size: 36% 100%;
			box-shadow: none;
			@include breakpoint($md) {
				.container {
					max-width: 1608px;
				}
			}
		}
		&__accent {
			width: 60%;
			height: gutter-size(2);
			margin-top: gutter-size(8);
			background: $secondary-dark;
		}
		&__nav-list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		&__nav-list-items {
			flex: 0 0 33.333%;
			padding: 0;
			@include breakpoint($md) {
				padding: gutter-size(4) gutter-size(6) gutter-size(4) 0;
			}
		}
		&__primary-search {
			float: right;
			width: auto;
			height: 100%;
			padding: gutter-size(4);
			text-align: right;
			border-radius: 0;
			border-top-left-radius: $border-radius-root;
			border-top-right-radius: $border-radius-root;

			.scl-header__secondary-icon {
				margin: 0;
			}
			&[aria-expanded="true"] {
				position: relative;
				background: $gray-100;
				border-radius: 0;
			}
			&:before {
				content: none;
			}
		}
		&__nav-list-title {
			display: block;
			margin-right: gutter-size(4);
			margin-bottom: gutter-size(4);
			font-weight: $font-weight-semibold;
		}
		&__primary-nav-item {
			height: auto !important;
			color: $white;
			font-size: $font-size-large;
			font-weight: 500;
			border-bottom: 4px solid $primary-dark;
			text-transform: uppercase;
			line-height: 1;
			#{$interact} {
				color: $secondary;
				border-bottom: 4px solid $secondary;
			}
			&[aria-expanded="true"] {
				color: $secondary;
				border-bottom: 4px solid $secondary;
			}
			@include breakpoint($lg) {
				margin-right: gutter-size(6);
			}
			&--active {
				color: $secondary;
				border-bottom: 4px solid $secondary;
			}
		}
		&__nav-list-link {
			min-height: auto;
			margin-right: gutter-size(4);
			margin-bottom: gutter-size(4);
			padding: 0;
			&:before {
				content: none;
			}
			#{$interact} {
				color: $primary !important;
			}
			.v-list-item__title {
				white-space: normal;
			}
		}
		&__btn-link {
			padding: 0 0 gutter-size(4) !important;
		}
		&__accordion {
			border-top: 1px solid $border-color;
			&:last-child {
				border-bottom: 1px solid $border-color;
			}
		}
		&__accordion-content {
			padding: gutter-size(5) 0 gutter-size(2);
			background: $gray-100;
		}
		&__search {
			position: relative;
			padding: gutter-size(3) 0 gutter-size(1);
			background: $gray-100;
			border-bottom-right-radius: $border-radius-root;
			border-bottom-left-radius: $border-radius-root;
			@include breakpoint($md) {
				padding: gutter-size(3) gutter-size(7.5) gutter-size(1);
			}
			&-menu {
				padding: 0;
				background: $gray-100;
				border-top: 1px solid $border-color;
				@include breakpoint($md) {
					background: transparent;
					border: none;
				}
			}
			&-input {
				color: $primary-dark;

				.v-input__slot {
					margin: 0;
					font-size: $font-size-root;
					box-shadow: none;
					input {
						color: $primary-dark;
						font-weight: $font-weight-semibold;
					}
				}
				.v-text-field__details {
					display: none;
				}
				.v-icon {
					margin-right: gutter-size(4);
					color: $primary-dark;
				}
				input::placeholder {
					color: $primary-dark;
					font-weight: $font-weight-semibold;
				}
			}

			&-btn {
				position: absolute;
				top: gutter-size(4.5);
				right: gutter-size(9);
			}
		}
		&__title {
			border-top: 1px solid $secondary;
			text-transform: uppercase;
			h1 {
				margin: gutter-size(8) gutter-size(1);
				font-size: 40px;
				@include breakpoint($md) {
					margin: gutter-size(13) 0;
					font-size: $font-size-H1-max;
				}
			}
		}
		&__options {
			padding: gutter-size(2.5);
			background: $secondary;
			&-item {
				display: flex;
				align-items: center;
				margin-right: gutter-size(10);
				color: $primary-dark;
				font-size: $font-size-small;
				&:last-child {
					margin: 0;
				}
			}
		}
		.v-expansion-panel-content__wrap {
			padding-bottom: 0;
		}
		.v-expansion-panel-header--active {
			color: $primary-dark;
		}
		.v-expansion-panel-header__icon {
			svg {
				color: $primary-dark;
			}
		}
		// print css
		@include print-css-hide;
	}
}
