$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-search-listing {
		&__sidebar {
			&--desktop {
				display: none;
			}
		}
		&__filter-button {
			width: 100%;
			margin-bottom: gutter-size(4);
		}
		&__filter {
			position: relative;
			height: 0;
			opacity: 0;
			transition: opacity $easing $timing-fast;
			&--active {
				height: auto;
				padding: gutter-size(4) 0;
				opacity: 1;
			}
		}
		&__clear {
			margin: gutter-size(2) 0;
			text-transform: unset;
		}
		&__page-row {
			> .col:last-of-type {
				border-bottom: 1px solid $gray-300;
			}
			> .col-12 {
				max-width: calc(100% - 24px);
			}
		}
		&__input {
			.v-input__slot {
				margin-bottom: gutter-size(6);
				border-left: 6px solid $secondary !important;
				box-shadow: inset rgba($gray-200, $opacity-lots) 0 0 10px;
			}
			.v-text-field__details {
				display: none;
			}
			.v-icon {
				color: $primary-dark;
			}
			input::placeholder {
				color: $accent;
			}
		}
		&__highlight {
			color: $primary;
		}
		.scl-page-size {
			margin: gutter-size(1) 0;
		}
		@include breakpoint($sm) {
			&__page-row {
				> .col {
					border-top: 1px solid $gray-300;
					border-bottom: 1px solid $gray-300;
				}
			}
		}
		@include breakpoint($md) {
			&__sidebar {
				&--desktop {
					display: block;
				}
				&--mobile {
					display: none;
				}
			}
			&__filter-button {
				display: none;
			}
			&__filter {
				height: auto;
				opacity: 1;
			}
		}
		// print css
		@media print {
			.scl-search-listing-facets,
			.scl-search-listing-sort {
				display: none;
			}
		}
	}
}
