$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-multi-child-step-framework {
		&__stepper-header {
			margin: 0;
			padding: 0;
			.v-stepper__label {
				display: block;
				width: 100%;
			}
			p {
				margin: 0;
				color: $accent;
			}
		}
		&__stepper-header-link {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			a {
				flex: 1 1 auto;
				color: $primary-dark;
				font-size: $font-size-root;
				text-align: right;
				text-decoration: underline;
			}
			h4 {
				flex: 1 1 auto;
				margin-bottom: gutter-size(4);
			}
		}
		&__stepper-content {
			margin: 0;
			padding: 0;
			border: none;
		}
		&__spacer {
			margin: gutter-size(16) 0;
			border-bottom: 1px solid $border-color;
		}
		.v-stepper__step__step {
			display: none !important;
		}
		.is-ee {
			.v-stepper__wrapper {
				height: auto !important;
			}
		}
	}
}
