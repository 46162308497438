$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-accordion {
		&__expansion-panel {
			margin: gutter-size(1) 0;
			color: $accent;
			border-bottom: 1px solid $border-color;
			&:first-child {
				border-top: 1px solid $border-color;
			}
			&:before,
			&:after {
				display: none;
			}
			@include breakpoint($md) {
				margin: gutter-size(2) 0;
			}
		}
		&__expansion-panel-header {
			min-height: 0;
			padding: gutter-size(5) gutter-size(4);
			font-weight: $font-weight-semibold;
			font-size: $font-size-root;
		}
		&__title {
			width: 100%;
			color: $accent;
			font-size: $font-size-H4-max;
		}
		&__icon {
			color: $primary-dark;
			&--minus {
				display: none;
			}
		}
		&__card {
			box-shadow: none;
		}
		&__card-text {
			color: $accent;
			.video {
				video {
					width: 100%;
				}
			}
			ol,
			ul,
			dl {
				margin: gutter-size(6) 0 gutter-size(6) gutter-size(4);
				padding: 0;
				ol,
				ul,
				dl {
					margin: 0 0 0 gutter-size(4);
				}
				@include breakpoint($sm) {
					margin: gutter-size(6) 0 gutter-size(6) gutter-size(10);
				}
			}
		}
		.v-expansion-panel-content__wrap {
			padding: gutter-size(4);
		}
		.v-expansion-panel--active {
			box-shadow: $shadow-box;
		}
		.v-expansion-panel-header--active {
			padding-bottom: 0;
			color: $primary-dark;
			.scl-accordion__icon--minus {
				display: flex;
			}
			.scl-accordion__icon--plus {
				display: none;
			}
		}
		// print css
	@media print {		
		.v-expansion-panel-content {
			display: block !important;
		}
		.scl-accordion__title {
			font-size: 16px;
		}
		.v-expansion-panel-header {
			padding: 10px 10px 0;
			.v-icon {
				display: none;
			}
		}
		.v-expansion-panel-content__wrap {
			padding: 0;
		}
	}
	}
	
}
