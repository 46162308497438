$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-minicart {
		position: relative;

		&__btn {
			&:before {
				content: none;
			}
		}
		&__number {
			position: absolute;
			top: gutter-size(-1.5);
			right: gutter-size(-1.5);
			width: gutter-size(4);
			height: gutter-size(4);
			font-size: $font-size-xsmall;
			text-align: center;
			background: $secondary;
			border-radius: 50%;
		}
	}
}
