$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-category-list {
		&__title {
			padding-bottom: gutter-size(6);
			border-bottom: 1px solid $border-color;
		}
		&__card {
			height: 100%;
			margin-bottom: gutter-size(6);
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
		}
		&__content {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			padding: gutter-size(5);
			@include breakpoint($sm) {
				padding: gutter-size(10);
				&--border {
					padding-left: 0;
					border-left: 1px solid $border-color;
				}
			}
		}
		&__card-title {
			font-weight: $font-weight-bold;
			font-size: $font-size-xlarge;
			word-break: normal;
		}
		&__text {
			font-weight: $font-weight-regular;
			font-size: $font-size-root;
			word-break: break-word;
			p {
				margin-bottom: 0;
			}
		}
		&__card-actions {
			display: block;
			padding-left: gutter-size(4);
			a {
				width: 100%;
				@include breakpoint($sm) {
					width: auto;
				}
			}
		}
	}
}
