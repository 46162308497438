$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-related-downloads {
		position: relative;
		&__title {
			margin-bottom: $font-size-root * $line-height-root;
			font-weight: $font-weight-bold;
			font-size: $font-size-xlarge;
		}
		&__link {
			display: block;
			margin-bottom: gutter-size(3);
			color: $primary-dark;
			font-weight: $font-weight-bold;
			#{$interact} {
				color: $primary;
				.scl-related-downloads__icon {
					color: $primary;
				}
			}
		}
		&__icon {
			margin-left: gutter-size(2);
			color: $primary-dark;
			font-size: $font-size-root;
		}
	}
}
