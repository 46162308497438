$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-payment-summary {
		position: relative;

		&__order-details {
			margin-bottom: gutter-size(10);
			padding: 0;
			list-style: none;
			li {
				@include h5;
				margin: gutter-size(2) 0;
				color: $accent;
				font-weight: $font-weight-bold;
			}
			span {
				display: inline-block;
				width: 130px;
				margin-right: gutter-size(2);
				color: $accent-light;
			}
		}
		&__list-container {
			margin-bottom: gutter-size(10);
		}
		&__list-title {
			position: relative;
			display: block;
			margin-bottom: gutter-size(6);
			font-weight: $font-weight-bold;
			span {
				position: relative;
				padding-right: gutter-size(4);
				background-color: $white;
			}
			&:before {
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				height: 2px;
				background-color: $secondary;
				transform: translateY(-50%);
				content: "";
			}
		}
		&__list {
			padding: 0;
			list-style: none;
			li {
				display: flex;
				align-items: center;
				justify-content: space-between;
				color: $accent;
				font-size: $font-size-root;
				line-height: 36px;
				> span:first-of-type {
					padding-right: gutter-size(3);
				}
			}
		}
		@include breakpoint($sm) {
			&__order-details {
				span {
					width: 200px;
				}
			}
		}
	}
}
