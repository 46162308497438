$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-grid-container-4 {
		&__title-header {
			display: block;
			@include breakpoint($sm) {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		&__title {
			margin-bottom: 0;
			font-weight: $font-weight-bold;
		}
		&__link {
			font-weight: $font-weight-bold;
			text-transform: unset;
		}
	}
}
