$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-image {
		&__content {
			display: flex;
			align-items: flex-end;
		}
		&__overlay {
			padding: gutter-size(4) gutter-size(8);
			color: $white;
			&--gradient {
				background-color: rgba(20, 20, 20, 0.6);
			}
		}
		&__caption {
			display: flex;
			justify-content: space-between;
			margin-bottom: gutter-size(3);
			padding: gutter-size(2) 0;
			color: $accent;
			font-size: $font-size-small;
			border-bottom: 2px solid $secondary;
			a {
				color: $accent;
				text-decoration: underline;
				&:hover {
					color: $accent;
				}
			}
			&:empty {
				display: none;
			}
			@include breakpoint($md) {
				font-size: $font-size-root;
			}
		}
	}
}
