$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-auth-login-modal {
		.v-dialog__content {
			top: 75.5px;
		}
		.v-dialog {
			max-height: 100%;
			margin: 0;
			border-radius: 0;
		}
		.v-card__text {
			padding: 0;
			p {
				color: $accent;
				font-weight: $font-weight-regular;
				font-size: $font-size-root;
			}
		}
		&__card {
			box-shadow: $shadow-box;
		}
		&__card-actions {
			display: flex;
			justify-content: flex-end;
		}
		&__tabs {
			display: flex;
			justify-content: center;
			border-bottom: 1px solid $gray-300;
			.v-btn {
				position: relative;
				top: 1px;
				margin: 0;
				text-transform: unset;
				background-color: $gray-100;
				border: 1px solid $gray-300;
				border-bottom: 1px solid $gray-300;
				border-radius: 0;
				&:nth-of-type(1) {
					border-top-left-radius: $border-radius-root;
				}
				&:nth-of-type(2) {
					left: -1px;
					border-top-right-radius: $border-radius-root;
				}
			}
			.v-btn__content {
				color: $accent;
				font-weight: $font-weight-semibold;
				font-size: $font-size-root;
			}
			.v-btn--disabled {
				background-color: $white !important;
				border-bottom: 0;
				.v-btn__content {
					color: $primary-dark;
				}
			}
		}
		&__tab {
			padding: gutter-size(5);
		}
		.v-input__slot {
			min-height: 48px;
		}
		.v-text-field--outlined fieldset {
			top: -10px;
		}
		.scl-checkout__form-btn {
			margin-top: 0;
			.v-progress-circular {
				margin: 0 auto;
			}
		}
		@include breakpoint($sm) {
			.v-dialog__content {
				top: 0;
			}
			.v-dialog {
				max-height: 90%;
				margin: gutter-szie(6);
				border-radius: $border-radius-root;
			}
			&__tabs {
				.v-btn {
					min-width: 260px;
				}
				.v-btn__content {
					justify-content: flex-start;
				}
			}
			&__tab {
				max-width: 520px;
				margin: 0 auto;
				padding: gutter-size(10) 0;
			}
		}
	}
}
