$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-key-info {
		position: relative;
		&__container {
			background-color: $white;
			border: 1px solid $border-color;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
			> div {
				padding: gutter-size(5);
				&:first-of-type {
					border-bottom: 1px solid $border-color;
				}
			}
		}
		&__title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: gutter-size(2);
		}
		&__ribbon {
			position: relative;
			right: gutter-size(-8);
			margin-left: gutter-size(2);
			padding: gutter-size(1) gutter-size(2);
			color: $white;
			font-weight: $font-weight-regular;
			font-size: $font-size-xsmall;
			line-height: 24px;
			background-color: $accent;
			border-radius: $border-radius-root;
			span {
				display: inline-block;
				white-space: nowrap;
			}
		}
		&__price {
			position: relative;
			display: inline-block;
			margin-bottom: 0;
			padding-right: gutter-size(3);
			sup {
				position: absolute;
				top: gutter-size(3);
				right: 0;
			}
		}
		&__flex-container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		&__date-list {
			margin-bottom: gutter-size(6);
			padding: 0;
			list-style: none;
		}
		&__calendar {
			font-weight: $font-weight-regular !important;
			text-transform: unset;
			text-decoration: underline;
		}
		&__select {
			fieldset {
				top: 0;
				border: 1px solid $border-color;
				legend {
					display: none;
				}
			}
			.v-input__slot {
				min-height: auto;
			}
			.v-input__append-inner {
				margin-top: gutter-size(3);
			}
			.v-select__selection {
				font-weight: $font-weight-semibold;
			}
		}
		&__btn {
			padding: 0 gutter-size(8);
			text-transform: unset;
			.v-btn__content {
				justify-content: space-between;
				> span {
					display: inline-block;
				}
			}
			&.v-btn--outlined {
				border-width: 2px;
			}
		}
		&__footnote {
			padding-top: gutter-size(2);
			color: $accent-light;
			font-size: $font-size-small;
			font-style: italic;
		}
		@include breakpoint($sm) {
			&__container {
				> div {
					padding: gutter-size(10);
				}
			}
			&__flex-container {
				flex-direction: row;
			}
			&__ribbon {
				right: gutter-size(-14);
				margin-left: gutter-size(4);
				padding: gutter-size(2) gutter-size(4);
				font-weight: $font-weight-semibold;
				font-size: $font-size-root;
			}
			&__btn {
				min-width: calc(50% - #{gutter-size(4)});
			}
		}
	}
}
