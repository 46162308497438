$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-search-curated-terms {
		&__card {
			padding: gutter-size(4);
			@include breakpoint($md) {
				padding: gutter-size(6);
			}
		}
		&__title {
			h5 {
				margin-bottom: gutter-size(8);
			}
		}
		&__btn {
			display: inline-block;
			min-height: 36px;
			margin-right: gutter-size(4);
			margin-bottom: gutter-size(4);
			color: $primary-dark;
			white-space: normal;
			text-transform: initial;
			background: $white;
			border: 2px solid $primary-dark;
			border-radius: 2px;
			box-shadow: none;
			.v-btn__content {
				height: 100%;
			}
			&:before {
				content: none;
			}
		}
	}
}
