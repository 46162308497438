$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-award-categories-list {
		position: relative;
		&__sort {
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
			&-header {
				display: flex;
				align-items: center;
			}
			&-title {
				margin-right: gutter-size(3);
				font-weight: $font-weight-semibold;
			}
			&-select {
				.v-input__slot {
					background: $gray-100;
					border: 1px solid $border-color;
					box-shadow: none;
				}
			}
		}
		&__item {
			display: flex;
			flex-direction: column;
			padding: gutter-size(6) 0;
			border-bottom: 1px solid $border-color;
			box-shadow: none;
			@include breakpoint($md) {
				flex-direction: row;
			}
		}
		&__btn {
			flex-direction: column;
		}
		&__content {
			flex: 1 1 auto;
			.btn-outlined {
				#{$interact} {
					color: $primary-dark;
					background: none;
					border-color: $primary-dark;
					cursor: default;
				}
			}
		}
		&__item-title {
			margin-bottom: gutter-size(2);
		}
		&__item-text {
			padding: gutter-size(2) gutter-size(4) 0 0;
			p {
				margin: 0;
			}
		}
		&__link {
			display: flex;
			flex: 0 0 auto;
			align-items: flex-end;
		}
	}
}
