$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-date-information-list {
		position: relative;
		&__control-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding-right: gutter-size(2);
		}
		&__title {
			@include h4;
			margin-bottom: 0;
			padding-bottom: 0;
			font-weight: $font-weight-bold;
		}
		&__item {
			margin: gutter-size(2) 0;
			padding: 0;
		}

		&__item-link {
			display: flex;
			align-items: center;
			width: 100% !important;
			border: 1px solid $gray-200;
			border-radius: $border-radius-root;
			box-shadow: none;
		}

		&__item-date {
			padding: gutter-size(4);
			color: $primary-dark;
			font-weight: $font-weight-bold;
			font-size: $font-size-H6-max;
			text-align: center;
		}

		&__item-content-wrapper {
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;
		}
		&__item-content {
			position: relative;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			padding: gutter-size(6) gutter-size(5);
			&:before {
				position: absolute;
				top: 16%;
				left: 0;
				width: 1px;
				height: 68%;
				background: $gray-200;
				content: "";
			}
			span {
				display: block;
			}
		}
		&__item-title {
			width: 100%;
			margin-bottom: 0;
		}
		&__item-text {
			margin-top: gutter-size(2);
			margin-bottom: 0;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		@include breakpoint($sm) {
			&__item-link {
				// align-items: flex-start;
			}
			&__item-content {
				position: absolute;
			}
			&__item-date {
				padding: gutter-size(6);
			}
		}
	}
}
