$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-article-list-item {
		position: relative;
		height: 100%;
		border-bottom: 1px solid $border-color;

		&__card {
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%;
			background: $secondary-dark;
			border: 1px solid $border-color;
			border-bottom: 8px solid $gray-200;
			border-radius: 0;
			.v-image {
				background: $white;
			}
			&-content {
				display: flex;
				flex-direction: column;
				height: 100%;
				padding: gutter-size(6);
				background: $gray-200;
			}
			&-title {
				align-items: flex-start;
				height: 100%;
				margin-bottom: gutter-size(2);
				padding: 0;
				color: $primary-dark;
				font-weight: $font-weight-black;
				word-break: normal;
			}
			&-text {
				padding: 0;
				font-size: $font-size-root;
				line-height: $line-height-root;
				color: $gray-600;
			}
			#{$interact} {
				border-bottom: 8px solid $secondary-dark;
				transition: all $easing $timing-fast;
				.scl-article-list-item__card-title {
					transition: all $easing $timing-fast;
					color: $secondary-dark;
				}
			}
		}
		&__item {
			padding-bottom: gutter-size(6);
		}
		&__title {
			margin-bottom: gutter-size(4);
			color: $accent;
			@include breakpoint($sm) {
				margin-bottom: gutter-size(6);
			}
		}
		&__title-link {
			display: flex;
			#{$interact} {
				color: $primary;
			}
		}
		&__title-icon {
			margin-right: gutter-size(2);
			color: $accent;
		}
		&__col {
			width: 100%;
		}
		&__content {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-end;
			margin-top: gutter-size(4);
			p {
				width: 100%;
				margin: 0;
				color: $accent;
			}
			@include breakpoint($sm) {
				flex-wrap: nowrap;
				margin-top: 0;
			}
		}
		&__link {
			margin-top: gutter-size(4);
			color: $primary-dark;
			font-weight: $font-weight-bold;
			text-align: right;
			.btn {
				padding: 0;
			}
			@include breakpoint($sm) {
				width: 40%;
				margin-top: 0;
				text-align: right;
			}
		}
		&__icon {
			display: inline-block;
			margin-left: gutter-size(2);
		}
		&__actions {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		&__actions-date {
			display: flex;
			order: 2;
			width: 100%;
			margin-bottom: gutter-size(2);
			color: $primary-dark;
			font-weight: $font-weight-semibold;
			@include breakpoint($sm) {
				order: 1;
				width: auto;
				padding-right: gutter-size(4);
				border-right: 1px solid $border-color;
			}
		}
		&__actions-member {
			display: flex;
			order: 3;
			width: 100%;
			margin-bottom: gutter-size(2);
			svg {
				margin-right: gutter-size(2);
				color: $accent;
				font-size: $font-size-root;
			}
			a {
				margin-left: gutter-size(1);
				color: $primary-dark;
				text-decoration: underline;
			}
			@include breakpoint($sm) {
				order: 2;
				width: auto;
				padding: 0 gutter-size(4);
			}
		}
		&__actions-type {
			display: flex;
			order: 3;
			width: 100%;
			margin-bottom: gutter-size(2);
			.scl-article-list-item__icon {
				margin-right: gutter-size(2);
				margin-left: 0;
				flex: none;
			}
			a {
				margin-left: gutter-size(1);
				color: $primary-dark;
				text-decoration: underline;
			}
			@include breakpoint($sm) {
				order: 2;
				width: auto;
				padding: 0 gutter-size(4);
			}
		}
		&__actions-featured {
			display: flex;
			order: 1;
			width: 100%;
			margin-bottom: gutter-size(2);
			.btn-outlined {
				padding: gutter-size(1) gutter-size(2);
				font-weight: $font-weight-regular;
				border-width: 1px;
				#{$interact} {
					color: $primary-dark;
					background-color: transparent;
					border-color: $primary-dark;
				}
			}
			svg {
				margin-right: gutter-size(2);
				color: $primary-dark;
				font-size: $font-size-root;
			}
			@include breakpoint($sm) {
				order: 3;
				width: auto;
				padding-left: gutter-size(4);
				border-left: 1px solid $border-color;
			}
		}
	}
}
