$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-breadcrumbs {
		ul {
			padding: gutter-size(3) 0 0;
		}
		a.v-breadcrumbs__item {
			font-size: $font-size-root;
			#{$interact} {
				text-decoration: underline;
			}
		}

		a.v-breadcrumbs__item.v-breadcrumbs__item--disabled {
			color: $gray-600;
			text-decoration: none;
		}

		.v-breadcrumbs__item {
			color: $primary-dark;
		}

		.v-breadcrumbs__divider {
			.v-icon {
				color: $primary-dark;
			}
		}
		// print css
		@include print-css-hide;
	}
}
