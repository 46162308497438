$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-events-search {
		&__input {
			.v-input__slot {
				border-left: 6px solid $secondary !important;
				box-shadow: inset rgba($gray-200, $opacity-lots) 0 0 10px;
			}
			.v-text-field__details {
				display: none;
			}
			.v-icon {
				color: $primary-dark;
			}
			input::placeholder {
				color: $accent;
			}
		}
		&__highlight {
			color: $primary;
		}
	}
}
