$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-content-carousel {
		&__header {
			display: block;
			@include breakpoint($sm) {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: gutter-size(6);
			}
		}
		&__header-title {
			margin-bottom: 0;
		}
		&__header-link {
			color: $primary-dark;
		}
		&__carousel {
			height: 100% !important;
			overflow: visible;
			.v-carousel__item {
				height: 100% !important;
				overflow: visible;
			}
			.v-window__container {
				height: 100% !important;
				padding-bottom: gutter-size(14);
			}
			.v-window__prev,
			.v-window__next {
				top: calc(100% - #{gutter-size(11)});
				z-index: zindex(level2);
				margin: 0;
				background: none;
				.v-btn {
					color: $primary-dark;
					svg {
						width: 32px !important;
						height: 32px !important;
						font-size: 32px !important;
					}
				}
			}
		}
		.v-carousel__controls {
			justify-content: center;
			&__item {
				margin: 0;
				&:before {
					display: none;
				}
				svg {
					width: gutter-size(4) !important;
					height: gutter-size(4) !important;
					color: $gray-100;
					font-size: $font-size-root !important;
					border: 2px solid $primary-dark;
					border-radius: 50%;
					opacity: 1;
				}
			}
			.v-item--active {
				svg {
					color: $primary-dark;
					background-color: $primary-dark;
				}
			}
		}

		.v-lazy {
			height: 100%;
		}
		&__card {
			display: flex;
			flex-direction: column;
			height: 100%;
			&:before {
				content: none;
			}
		}
		&__content {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: gutter-size(2) gutter-size(6);
		}
		&__title {
			font-weight: $font-weight-bold;
			word-break: break-word;
		}
		&__text {
			margin-bottom: auto;
			font-weight: $font-weight-regular;
			font-size: $font-size-root;
			p {
				margin-bottom: 0;
				word-break: break-word;
			}
		}
		&__list {
			display: flex;
			align-items: center;
			min-height: 17px;
			margin: gutter-size(3) 0 gutter-size(2);
			padding: 0;
			list-style: none;
		}
		&__label {
			display: flex;
			margin-right: gutter-size(2);
			padding-right: gutter-size(2);
			color: $accent-light;
			font-size: $font-size-small;
			line-height: gutter-size(4);
			border-right: 1px solid $accent-lighter;
			&:last-of-type {
				margin-right: 0;
				padding-right: 0;
				border-right: none;
			}
		}
	}
}
