$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-spacer {
		@include clearfix();
		font-size: 0;
		line-height: 0;

		&--minimum {
			height: gutter-size(6);
		}

		&--medium {
			height: gutter-size(12);
		}

		&--maximum {
			height: gutter-size(12);
		}

		@include breakpoint($sm) {
			&--medium {
				height: gutter-size(10);
			}

			&--maximum {
				height: gutter-size(20);
			}
		}
	}
}
