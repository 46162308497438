$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-footer-search {
		background: $gray-100;
		&__title {
			@include h4;
			margin-bottom: gutter-size(2.5);
			color: $accent;
			font-weight: $font-weight-bold;
		}
		&__title-icon {
			display: none;
			@include h4;
			margin-right: gutter-size(5);
			color: $accent;
		}
		&__input {
			.v-input__slot {
				margin-bottom: gutter-size(8);
				border-left: 6px solid $secondary;
			}
			.v-text-field__details {
				display: none;
			}
			.v-icon {
				color: $primary-dark;
			}
			input::placeholder {
				color: $accent-light;
			}
		}
		@include breakpoint($md) {
			padding: gutter-size(10) 0 0;
			&__title {
				margin-bottom: gutter-size(4.5);
			}
			&__title-icon {
				display: inline-block;
			}
			&__input {
				margin-left: gutter-size(12.5);
			}
		}
	}
}
