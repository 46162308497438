$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-progress {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 378px;
		&__circle {
			position: relative;
			flex-shrink: 0;
			width: 32px;
			height: 32px;
			border: 8px solid #abafb2;
			border-radius: 50%;
			transition: border $timing-faster $easing;
			span {
				position: absolute;
				bottom: -#{gutter-size(9)};
				left: -#{gutter-size(2)};
				display: block;
				width: 100px;
				color: $accent;
			}
			&.is-filled {
				border: 8px solid $primary-dark;
			}
		}
		&__circle-middle {
			span {
				left: 50%;
				transform: translateX(-50%);
			}
		}
		&__circle-last {
			span {
				right: -#{gutter-size(2)};
				left: auto;
				text-align: right;
			}
		}
		&__bar {
			width: 100%;
			height: 8px;
			background-color: #abafb2;
			transition: background-color $timing-faster $easing;
			&.is-filled {
				background-color: $primary-dark;
			}
		}
		@include breakpoint($sm) {
			margin-left: auto;
		}
	}
}
