$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-region-selector {
		&__list {
			display: flex;
			flex-wrap: wrap;
			list-style-type: none;
			gap: 16px;
			padding: 0;
			@include breakpoint($md) {
				flex-wrap: nowrap;
			}
		}
		&__list-item {
			flex: 1 1 auto;
			width: calc(33.333% - 16px);
			a {
				display: block;
			}
			border: 1px solid $border-color;
			.v-image {
				width: 100%;
				.v-responsive__content {
					width: auto !important;
				}
			}
			&__content {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: gutter-size(5);
				color: $primary-dark;
				font-weight: $font-weight-bold;
				background: #EBEBED;
				.v-icon {
				color: $primary-dark;

				}
			}
		}

		// print css
		@include print-css-hide;
	}
}
