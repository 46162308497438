$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-product-listing {
		position: relative;

		&__filter-button {
			width: 100%;
			margin-bottom: gutter-size(4);
			@include breakpoint($md) {
				display: none !important;
			}
		}
		&__filter {
			position: relative;
			height: 0;
			opacity: 0;
			transition: opacity $easing $timing-fast;
			&--active {
				height: auto;
				padding: gutter-size(4) 0;
				opacity: 1;
				&:before {
					position: absolute;
					top: 0;
					left: gutter-size(-7);
					width: 200%;
					height: 100%;
					background: $gray-100;
					content: "";
				}
			}
			@include breakpoint($md) {
				height: auto;
				opacity: 1;
				&--active {
					&:before {
						content: none;
					}
				}
			}
		}
		&__sorting {
			position: relative;
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
		}
		&__sorting-col {
			padding: gutter-size(2) gutter-size(4);
		}
		&__clear {
			float: right;
			text-transform: unset;
		}
		// print css
		@media print {
			.scl-product-search,
			.scl-product-listing__filter,
			.scl-product-page-size,
			.scl-product-sort {
				display: none;
			}
		}
	}
}
