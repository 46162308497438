$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-secondary-header-banner {
		position: relative;
		> a {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		&__text {
			position: relative;
			z-index: 1;
			font-weight: $font-weight-semibold;
		}
		&__has-image {
			padding: 0 gutter-size(3);
			@include breakpoint($sm) {
				padding: 0 gutter-size(16) 0 gutter-size(13);
			}
		}
		&__no-image {
			padding: 0;
		}
		&__headline {
			font-size: $font-size-xlarge;
			@include breakpoint($sm) {
				max-width: 70%;
			}
			@include breakpoint($md) {
				max-width: 60%;
			}
			@include breakpoint($lg) {
				max-width: 50%;
			}
		}
		&__title {
			margin-bottom: gutter-size(4);
		}
		&__white {
			color: $white;
			h1 {
				color: $white;
			}
		}
		&__black {
			color: $black;
		}
		&__overlay {
			&:after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba($accent, $opacity-little);
				background: linear-gradient(30deg, rgba($accent, 1) 7%, rgba($accent, 0) 100%);
				content: "";
			}
		}
		&__link {
			position: absolute;
			bottom: 0;
			z-index: zindex(level1);
			padding: 0 gutter-size(4) gutter-size(5.5);
			font-style: italic;
			span {
				margin-left: gutter-size(1);
				text-decoration: underline;
				cursor: pointer;
			}
			@include breakpoint($sm) {
				text-align: right;
			}
		}
		&__small {
			max-height: 195px;
			.scl-secondary-header-banner__text {
				margin-top: 0;
			}
		}
		.text-center {
			.scl-secondary-header-banner__headline {
				margin: 0 auto;
			}
		}
		.text-right {
			.scl-secondary-header-banner__headline {
				margin: 0 0 0 auto;
			}
		}
	}
}
