$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-social-share {
		&__button {
			width: gutter-size(4);
			height: gutter-size(4);
			padding: gutter-size(4);
		}
		&__icon {
			width: gutter-size(4);
			height: gutter-size(4);
			color: $primary-dark;
			#{$interact} {
				color: $primary;
			}
		}
		// print css
		@include print-css-hide;
	}
}
