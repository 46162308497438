$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-card-icon {
		height: 100%;
		&__card {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding: gutter-size(6) gutter-size(2);
			border: 1px solid $border-color !important;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
			&:before {
				content: none;
			}
		}
		&__image {
			display: flex;
			width: 100%;
			padding: gutter-size(4);
		}
		&__content {
			margin-bottom: auto;
			padding: 0 gutter-size(4) gutter-size(16);
		}
		&__title {
			margin-bottom: gutter-size(2);
		}
		&__label {
			display: inline-block;
			padding: gutter-size(0.5) gutter-size(2);
			color: $primary-dark;
			font-weight: $font-weight-bold;
			border: 2px solid $primary-dark;
			border-radius: $border-radius-root;
		}
		&__text {
			margin-top: gutter-size(4);
			font-weight: $font-weight-regular;
			font-size: $font-size-root;
			p {
				margin-bottom: 0;
			}
		}
		&__icon {
			margin-left: gutter-size(2);
			font-size: $font-size-root;
		}
		&__card-actions {
			display: block;
			margin-top: auto;
			padding-left: gutter-size(4);
			.btn-link {
				padding: 0;
			}
		}
	}
}
