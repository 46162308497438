$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-wysiwyg,
	.scl-ww {
		position: relative;
		a:not(.btn) {
			color: $link-color;
			text-decoration: $link-decoration;
			#{$interact} {
				color: $link-hover-color;
				text-decoration: $link-hover-decoration;
			}
		}
		img {
			max-width: 100%;
			height: auto;
			margin: gutter-size(5) 0;
		}
		ol,
		ul,
		dl {
			margin: gutter-size(6) 0 gutter-size(6) gutter-size(4);
			padding: 0;
			ol,
			ul,
			dl {
				margin: 0 0 0 gutter-size(4);
			}
			@include breakpoint($sm) {
				margin: gutter-size(6) 0 gutter-size(6) gutter-size(10);
			}
		}
		dd,
		dt,
		li {
			line-height: $line-height-root;
		}
		.btn-primary {
			color: $white;
		}
		.btn-outlined,
		.btn-link {
			color: $primary-dark;
		}
		&__summaries {
			margin: gutter-size(12) 0;
			padding: gutter-size(10) 0 gutter-size(8);
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
			h4 {
				margin-bottom: gutter-size(2);
			}
			ul {
				margin: 0 gutter-size(4);
				@include breakpoint($sm) {
					column-count: 2;
					column-gap: gutter-size(6);
				}
				li {
					color: $accent-light;
					font-weight: $font-weight-bold;
				}
				a:not(.btn) {
					color: $accent-light;
					text-decoration: none;
					#{$interact} {
						color: $link-hover-color;
						text-decoration: underline;
					}
				}
			}
		}
		&__list-style-2,
		.bulleted-list-style-2 {
			padding-left: gutter-size(4);
			list-style: none;
			li {
				position: relative;
				display: inline-block;
				width: 100%;
				margin-top: gutter-size(2);
				margin-bottom: gutter-size(2);
				margin-left: gutter-size(4);
				color: $accent-light;
				font-weight: $font-weight-semibold;
				&:before {
					position: absolute;
					top: gutter-size(1);
					left: gutter-size(-4);
					display: block;
					width: gutter-size(2);
					height: gutter-size(4);
					background: url("#{$image-path}/list-bullet.png") no-repeat;
					content: "";
				}
			}
		}
		&__hero-text,
		.hero-text {
			font-size: $font-size-H4-max;
		}
		&__blockquote {
			position: relative;
			display: none;
			@include breakpoint($sm) {
				display: flex;
			}
			&:before {
				position: relative;
				display: block;
				width: 100%;
				max-width: 139px;
				@include blockquote($secondary-dark);
				background-repeat: no-repeat;
				background-size: contain;
				content: "";
			}
		}
		&__blockquote-content-wrapper {
			margin-top: gutter-size(15);
			margin-left: gutter-size(7.5);
		}
		&__blockquote-content {
			padding-left: gutter-size(10);
			border-left: 8px solid $secondary-dark;
			p {
				margin-bottom: 0;
			}
		}

		&__blockquote-cite {
			padding-left: gutter-size(12);
			font-size: $font-size-root;
		}
		&__table {
			width: 100%;
			margin-bottom: gutter-size(4);
			text-align: left;
			background-color: $white;
			border-collapse: separate;
			border-spacing: 2px;
			td {
				padding: gutter-size(4);
				line-height: $line-height-sm;
			}
			tr:nth-child(2n + 1) {
				background-color: $gray-100;
			}
			tr:nth-child(2n + 1) td:first-child {
				width: 25%;
				border-top-left-radius: 4px;
			}
			tr:nth-child(2n + 1) td:last-child {
				border-top-right-radius: 4px;
			}
			&--yellow {
				tr:nth-child(2n) td {
					background-color: rgba($secondary, 0.2);
				}
				tr:nth-child(2n + 1) td {
					background-color: rgba($secondary, 0.44);
				}
			}
			&--comparison {
				td.tick {
					@include table-tick($primary-dark);
					background-repeat: no-repeat;
					background-position: center center;
				}
				tr:nth-child(2n) td {
					background-color: rgba($secondary, 0.2);
				}
				tr:nth-child(2n + 1) td {
					background-color: rgba($secondary, 0.44);
				}
				tr:nth-child(2n) td:first-child {
					background-color: $gray-100;
				}
				tr:nth-child(2n + 1) td:first-child {
					background-color: $white;
				}

				tr:first-child td:first-child {
					color: $white;
					background-color: $accent;
				}
				td[colspan="1"],
				td[colspan="2"],
				td[colspan="3"],
				td[colspan="4"],
				td[colspan="5"],
				td[colspan="6"],
				td[colspan="7"],
				td[colspan="8"],
				td[colspan="9"],
				td[colspan="10"],
				td[colspan="11"],
				td[colspan="12"],
				td[colspan="13"],
				td[colspan="14"],
				td[colspan="15"],
				td[colspan="16"],
				td[colspan="17"],
				td[colspan="18"],
				td[colspan="19"],
				td[colspan="20"] {
					font-weight: $font-weight-bold;
					line-height: $line-height-sm;
					background-color: rgba($secondary, 0.74) !important;
				}
			}
			tr:first-child td {
				min-width: 200px;
				font-weight: $font-weight-bold;
				background-color: $secondary;
			}
			&--yellow-header {
				tr:first-child td {
					width: auto !important;
					background-color: $secondary;
				}
			}
		}
		&__table-responsive {
			display: block;
			width: 100%;
			overflow-x: auto;
		}
		&__video--embedded {
			width: 100%;
		}
		&__video--vimeo,
		&__video--youtube {
			position: relative;
			&:before {
				display: block;
				padding-top: 56.25%;
				content: "";
			}
			iframe {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				border: 0;
			}
		}
		&__accent-snippet {
			padding: gutter-size(4);
			border-left: 6px solid $secondary;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
		}
	}
}
