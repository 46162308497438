$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-header-contact {
		&__text {
			display: none;
			margin: 0;
			font-size: $font-size-small;
			font-weight: $font-weight-regular;
			@include breakpoint($md) {
				display: block;
			}
		}
		&__number {
			a {
				margin: 0;
				color: $white;
				font-size: $font-size-H4-max;
				font-weight: $font-weight-bold;
				line-height: $line-height-sm;
				@include breakpoint($md) {
					color: $secondary;
				}
			}
			.v-icon {
				color: $secondary;
				transform: rotateY(180deg);
			}
		}
	}
}
