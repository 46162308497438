/*! generic */

// hides app until vue is loaded
[v-cloak] {
	display: none;
}

// on load of vue flip opacity and transition in
#app-main {
	opacity: 0;
	transition: opacity $timing-fast $easing;
	&.app-loaded {
		opacity: 1 !important;
	}
}
// override default modal overlay
.v-overlay--active {
	.v-overlay__scrim {
		opacity: 0.75 !important;
	}
}

// override black text class with accent
.v-application .black--text {
	color: $accent !important;
	caret-color: $accent !important;
}

.v-application {
	background: $gray-100 !important;
}