$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-tags {
		position: relative;
		.v-chip {
			color: $gray-900;
			font-size: 16px;
		}
		&__link {
			cursor: pointer;
		}
		&__icon {
			cursor: pointer;
		}
		// print css
		@include print-css-hide;
	}
}
