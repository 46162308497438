$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-events-list-item {
		position: relative;
		height: 100%;
		.v-lazy {
			height: 100%;
		}
		&__item {
			display: flex;
			flex-direction: column;
			height: 100%;
			padding-bottom: gutter-size(6);
		}
		&__title {
			margin-bottom: gutter-size(4);
			color: $accent;
			#{$interact} {
				color: $primary;
			}
			@include breakpoint($sm) {
				margin-bottom: gutter-size(6);
			}
		}
		&__col {
			width: 100%;
		}
		&__content {
			height: 100%;
			margin-bottom: auto;
			padding: gutter-size(6);
		}
		&__link {
			display: block;
			width: 100%;
			margin-top: auto;
			padding-left: gutter-size(6);
			color: $primary-dark;
			font-weight: $font-weight-bold;
			.btn {
				padding: 0;
			}
		}
		&__icon {
			margin-left: gutter-size(2) !important;
			color: $primary-dark;
		}
		&__event-info {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		&__date {
			display: flex;
			width: 100%;
			margin-bottom: gutter-size(2);
			color: $primary-dark;
			font-weight: $font-weight-semibold;
			@include breakpoint($sm) {
				width: auto;
				padding-right: gutter-size(4);
			}
		}
		&__location {
			display: flex;
			align-items: center;
			width: 100%;
			margin-bottom: gutter-size(2);
			font-size: $font-size-small;
			svg {
				margin-right: gutter-size(2);
				color: $primary-dark;
				font-size: $font-size-root;
			}
			@include breakpoint($sm) {
				width: auto;
				padding: 0 gutter-size(4);
				border-left: 1px solid $border-color;
			}
		}
	}
}
