$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-card-horizontal {
		&__auto {
			height: auto;
		}
		&__fill-height {
			height: 100%;
		}
		&__row {
			@include breakpoint($md) {
				align-items: flex-end;
				height: 100%;
			}
		}
		&__card {
			height: 100%;
			overflow: hidden;
			border: 1px solid $border-color;
			border-radius: $border-radius-root;
			box-shadow: $shadow-box;
		}
		&__media {
			display: flex;
			height: auto;
			@include breakpoint($md) {
				align-self: stretch;
			}
		}
		&__content {
			padding: gutter-size(5) gutter-size(8) gutter-size(1) gutter-size(8);
			@include breakpoint($md) {
				padding: gutter-size(5) gutter-size(6) gutter-size(1) gutter-size(3);
			}
			@include breakpoint($lg) {
				padding: gutter-size(7) gutter-size(8) gutter-size(3) gutter-size(5);
			}
		}
		&__image {
			height: 100%;
		}
		&__title {
			font-weight: $font-weight-bold;
			font-size: $font-size-xlarge;
			word-break: break-word;
		}
		&__text {
			font-weight: $font-weight-regular;
			font-size: $font-size-root;
			p {
				margin-bottom: 0;
				word-break: break-word;
			}
		}
		&__icon {
			margin-left: gutter-size(2);
			font-size: $font-size-root;
		}
		&__card-title {
			padding: 0;
		}
		&__card-actions {
			display: block;
			padding: 0;
			.btn-link {
				padding-right: 0;
			}
		}
		.theme--dark {
			.scl-card-horizontal__title {
				color: $white;
			}
		}
	}
}
