$env: production;$image-path: /scl/assets/img;$font-path: /scl/assets/fonts;@import "@/_scl-fed/assets/css/settings/_variables.scss";@import "~breakpoint-sass/stylesheets/_breakpoint.scss";@import "@/_scl-fed/assets/css/tools/_mixins.scss";
#app {
	.scl-key-info-product {
		position: relative;
		background-color: $gray-100;
		border: 1px solid $border-color;
		border-radius: $border-radius-root;
		box-shadow: $shadow-box;
		&__type-container {
			display: none;
			padding: gutter-size(6);
			border-bottom: 1px solid $border-color;
		}
		&__resource-container {
			padding: gutter-size(4);
		}
		&__title {
			margin-bottom: gutter-size(1);
		}
		&__description-container {
			p {
				margin-bottom: gutter-size(4);
			}
			ul {
				padding-left: gutter-size(4);
				list-style-type: disc;
			}
			li {
				margin-bottom: gutter-size(4);
			}
		}
		&__image-container {
			position: relative;
			.v-image {
				border-radius: $border-radius-root;
			}
		}
		&__icon-container {
			position: absolute;
			right: gutter-size(-2);
			bottom: gutter-size(-4);
			background-color: $white;
			border: 2px solid $primary-dark;
			border-radius: 50%;
			opacity: 1;
			transition: opacity $timing-fastest $easing;
			#{$interact} {
				cursor: pointer;
				opacity: $opacity-lots;
			}
			.v-icon {
				margin: gutter-size(4);
			}
		}
		hr {
			display: block;
			height: 1px;
			margin: gutter-size(4) 0;
			line-height: 1px;
			background-color: $gray-300;
			border: 0;
		}
		&__price {
			display: inline-block;
			&:nth-of-type(2) {
				margin-right: gutter-size(6);
			}
			p {
				margin-bottom: gutter-size(2);
				font-size: $font-size-xsmall;
			}
			&--strike-through {
				text-decoration: line-through;
			}
		}
		&__cta {
			padding: 0 gutter-size(6);
			text-transform: unset;
			.v-btn__content {
				display: flex;
				justify-content: space-between;
			}
			.v-progress-circular {
				margin: 0 auto;
			}
		}
		&__dlcta {
			justify-content: space-between;
			width: 100%;
			margin-top: gutter-size(2);
			padding-left: gutter-size(8);
			color: $white;
			text-transform: unset;
		}
		@include breakpoint($md) {
			&__resource-container {
				padding: gutter-size(10);
			}
			&__price {
				&:nth-of-type(2) {
					margin-right: gutter-size(12);
				}
			}
		}
	}
}
